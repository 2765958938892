import { createRequestHeaders, createRequestUrl, handleFetchResponse } from 'utils/request';
import { COUNTRIES_ROUTE } from 'utils/routes';
import { defaultLocale } from 'utils/translations';

type GetCountriesReturn = {
  countries: {
    id: string;
    name: string;
    languages: {
      id: string;
      name: string;
      default: boolean;
    }[];
    globale: boolean;
    esw: boolean;
  }[];
};

export async function getCountries({ locale = defaultLocale }: { locale?: string }) {
  const url = createRequestUrl({ path: COUNTRIES_ROUTE, query: { profile: locale } });
  try {
    const r = await fetch(url, {
      method: 'GET',
      headers: createRequestHeaders(),
    });
    const response = await handleFetchResponse<GetCountriesReturn>(r);
    if (response.ok === false) {
      throw response.error;
    }
    return response.json;
  } catch (error) {
    console.error(`Error in: ${url.toString()}`);
    console.error(error);
    return null;
  }
}
