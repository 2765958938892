import TagManager from 'react-gtm-module-custom-domain';
import md5 from 'md5';
import crypto from 'crypto-js';

import { categories, setGTMStorage, getMonitorValues } from 'utils/gtmUtils';

import { getCookie, COOKIES, handleStorage, getMarket, getLang, getAppliedDiscount } from './helpers';

import { GMT_DOMAIN_ID, GTM_COMMON_ID } from './constants/gtm';
import { IS_CAMPER } from './constants/system';
import { storage } from './web-storage';
import { getDomainValue } from 'utils/domain';
import { SESSION_STORAGE_KEYS } from './constants/storage';

const { SHA256 } = crypto;

const {
  parseProductsGTM,
  parseEanGTM,
  parseItemsBagGTM,
  parseItemsOrderGTM,
  parseProductsEcommerce,
  parseBagEcommerceGTM,
  parseProductsEcommerceGA4,
  parseBagItemsEcommerceGA4,
} = require('utils/gtmUtils');

function gtag() {
  TagManager.dataLayer({
    // eslint-disable-next-line prefer-rest-params
    dataLayer: arguments,
  });
}

const { getDomain } = require('utils/domain');

export const slotIndexes = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'U', 'V', 'W', 'X', 'Y', 'Z'];
export const CAMPAIGN_ID = 'INTERNAL-CAMPAIGN';
export const SLOT_WITH_IMAGE = 'image';

const pushNullEcommerce = async (origin) => {
  // console.log('pushing ecommerce null for', origin);
  await TagManager.dataLayer({
    dataLayer: { ecommerce: null },
  });
};

const initTagManager = async (layer, action = null) => {
  let additionalKeys = {};

  if (action !== null) {
    additionalKeys = await action();
  }
  const dataLayerToPush = { ...layer, ...additionalKeys };

  if ('ecommerce' in dataLayerToPush) {
    await pushNullEcommerce(dataLayerToPush.event);
  }

  if (dataLayerToPush && Object.keys(dataLayerToPush).length > 0) {
    await TagManager.dataLayer({
      dataLayer: dataLayerToPush,
    });
  }
};

export const dataLayerHandleEvent = (eventData = {}) => {
  initTagManager(
    {
      ...eventData,
    },
    null,
  );
};

export default async function dataLayer(type, email, isMobile, lng_home, country_home, gtmProps) {
  const device = isMobile ? 'mobile' : 'desktop';
  const appmode = typeof window !== 'undefined' ? getCookie(COOKIES.APP_IPAD) === 'true' : false;
  const customURL = getDomainValue({
    camper: 'https://gtm.camper.com/gtm.js',
    nnormal: 'https://gtm.nnormal.com/gtm.js',
    camperlab: 'https://gtm.camperlab.com/gtm.js',
  });
  const tagManagerArgs = {
    gtmId: GMT_DOMAIN_ID,
    customURL,
  };

  let emailMD5 = email !== '' ? md5(email) : '';

  if (gtmProps?.email) {
    // no veo que se llame a dataLayer con el email en ningún sitio
    emailMD5 = md5(gtmProps.email);
  }

  const [lang, market] = [getLang(gtmProps.locale), getMarket(gtmProps.locale)];

  const { page_section, page_category, page_type, page_detail } = getMonitorValues(`${lng_home}_${country_home}`, type, gtmProps);

  // si podemos sacar page_standard de API a lo urlcanonical sería lo mejor
  const baseLayer = {
    type,
    customer: emailMD5,
    device: device === 'desktop' && appmode ? 'app-store' : device,
    idioma: lng_home,
    pais: country_home?.toLowerCase(),
    page_section,
    page_category,
    page_type,
    page_detail,
    page_language: lang,
    page_country: market?.toLowerCase(),
    currency: gtmProps?.profileData?.currencySap ?? null,
    domain: getDomain(),
  };

  if (gtmProps?.profileData?.engUrl) {
    baseLayer.page_standard = gtmProps.profileData.engUrl.split('en_ES')[1] || '/';
  } else {
    const currentUrl = window.location.pathname.split(gtmProps.locale)[1] || '/';
    baseLayer.page_standard = currentUrl;
  }

  let gtmPropsKeys = {};

  if (gtmProps !== null && gtmProps !== undefined) {
    const { product: family, familyRealID: family_id, target: gender, targetRealID: gender_id, productSheet } = gtmProps;

    gtmPropsKeys = {
      family,
      family_id,
      gender,
      gender_id,
      productSheet,
    };
  }
  window.gtmType = type?.toLowerCase() || '';
  switch (type) {
    case 'grid':
      delete gtmPropsKeys.productSheet;
      gtmPropsKeys.items = parseProductsGTM(gtmProps?.products);
      baseLayer.isLab = gtmProps?.camperlab || false;
      await initTagManager(
        {
          ...baseLayer,
          ...gtmPropsKeys,
        },
        null,
      );
      await TagManager.initialize(tagManagerArgs);
      await initTagManager({}, async () => {
        if (gtmProps) {
          const currency = gtmProps?.products?.length > 0 ? gtmProps.products[0].prices.currency : '';

          // GA4

          setGTMStorage(gtmProps?.currentListId, gtmProps?.currentListName ?? gtmProps?.currentList);
          const eventLayerGA4 = {
            event: 'view_item_list',
            nonInteraction: '1',
            ecommerce: {
              currency,
              items: parseProductsEcommerceGA4(gtmProps?.products, gtmProps?.currentListId, gtmProps?.currentListName ?? gtmProps?.currentList, gtmProps?.page),
            },
          };
          await pushNullEcommerce(eventLayerGA4.event);
          TagManager.dataLayer({
            dataLayer: eventLayerGA4,
          });
          // end GA4
          return {};
        }
        return {};
      });

      break;
    case 'detail':
    case 'CamperONEDim':
      {
        const { code: item, line: line_id, brand: material_name, tipology, sizes } = gtmPropsKeys.productSheet;

        gtmPropsKeys = {
          ...gtmPropsKeys,
          item,
          line_id,
          material_name,
          tipology,
          ean: parseEanGTM(sizes),
        };
        delete gtmPropsKeys.productSheet;

        const prices = gtmProps.productSheet !== null && gtmProps.productSheet.prices !== null ? gtmProps.productSheet.prices : null;

        baseLayer.type = 'detail';
        baseLayer.isLab = gtmProps?.productSheet?.camperlab || false;
        await initTagManager(
          {
            ...baseLayer,
            ...gtmPropsKeys,
            related: '[]',
          },
          () => {
            const currentPrice = prices === null ? 0 : prices.current;
            let oldPrice = 0;
            if (prices !== null && prices.previous !== undefined && prices.previous !== prices.current) {
              oldPrice = prices.previous;
            }

            return { old_price_item: oldPrice, price_item: currentPrice };
          },
        );

        await TagManager.initialize(tagManagerArgs);

        const { category, nameBase, prices: pdpPrices, target, color } = gtmProps.productSheet;

        const productCategory = category?.split('/');
        const currentPrice = prices === null ? 0 : prices.current;
        const currentPriceEur = prices === null ? 0 : prices.currentEur;

        const currentList = handleStorage('getItem', 'gtmCurrentList');
        const currentListUrl = handleStorage('getItem', 'gtmCurrentListUrl');
        // const currentItemIndex = handleStorage('getItem', 'gtmCurrentItemIndex') || 1;
        const currentListName = handleStorage('getItem', 'gtmCurrentListName');

        // const currentListSplit = currentList ? currentList.split('|') : '';
        // const currentListId = currentList && Array.isArray(currentListSplit) ? currentListSplit[0] : '';
        // const currentListName = currentList && Array.isArray(currentListSplit) && currentListSplit.length > 1 ? currentListSplit[1] : '';
        const lastVisitedUrl = document?.referrer ? document?.referrer.split('?')[0] : null;

        const selectData = handleStorage('getItem', 'gtmSelectData') === undefined ? {} : JSON.parse(handleStorage('getItem', 'gtmSelectData'));
        const gtmIndex = Object.keys(selectData).includes(item) ? selectData[item]?.index : null;
        const itemListName = selectData[item]?.item_list_name || (currentListUrl === lastVisitedUrl ? currentListName : '');
        const itemListId = selectData[item]?.item_list_id || (currentListUrl === lastVisitedUrl && currentList ? currentListName.replaceAll(' ', '_') : '');
        let item_category5 = productCategory && Array.isArray(productCategory) ? productCategory.pop() : '';

        if (item_category5 === 'DISCOUNT-0.0') {
          item_category5 = '';
        }

        const pdpDataLayerGA4 = {
          event: 'view_item',
          ecommerce: {
            items: [
              {
                item_name: material_name?.toLowerCase() || '',
                item_id: item,
                price: currentPrice,
                price_eur: currentPriceEur,
                price_net: prices?.netPrice ?? 0,
                discount: getAppliedDiscount(pdpPrices),
                discount_eur: pdpPrices?.discountEur || 0,
                item_brand: 'camper',
                item_category: target in categories ? categories[target] : '', // 'women
                item_category2: productCategory?.length >= 2 ? productCategory[1].toLowerCase() : '', // shoes
                item_category3: productCategory?.length >= 2 ? productCategory[2].toLowerCase() : '', // sandals
                item_category4: nameBase?.toLowerCase() || '', // <linea>
                item_category5,
                item_variant: color?.toLowerCase() || '',
                item_list_name: itemListName || null,
                item_list_id: itemListId?.toLowerCase() || null,
                index: gtmIndex,
                currency: pdpPrices?.currencySap || '',
                quantity: 1,
              },
            ],
          },
        };

        await initTagManager(pdpDataLayerGA4, null);
      }
      break;
    case 'home':
      await initTagManager(baseLayer, null);
      await TagManager.initialize(tagManagerArgs);
      break;
    case 'bag':
      if (gtmProps !== null) {
        await initTagManager({
          ...baseLayer,
          id_bag: gtmProps.bagId || gtmProps.bagHash,
          items: parseItemsBagGTM(gtmProps.bag),
        });
        await TagManager.initialize(tagManagerArgs);
        await initTagManager({}, async () => {
          // GA4
          const bagFormatted = gtmProps?.bag.map((product) => ({ ...product, prices: product.price, id: product.productId }));
          const value = bagFormatted.map((item) => item.prices?.current).reduce((acc, item) => acc + item, 0);
          const eventLayerViewCartGA4 = {
            event: 'view_cart',
            ecommerce: {
              value,
              currency: bagFormatted.length ? bagFormatted[0].prices?.currency : '',
              items: parseProductsEcommerceGA4(bagFormatted), // no pasamos list name porque tiene que sacarla de storage
            },
          };
          await pushNullEcommerce(eventLayerViewCartGA4.event);
          TagManager.dataLayer({
            dataLayer: eventLayerViewCartGA4,
          });
          // end GA4
          return {};
        });
      }
      break;
    case 'Purchase':
      if (gtmProps !== null) {
        await initTagManager({
          ...baseLayer,
          type: 'checkout',
          id_bag: gtmProps.bagId || gtmProps.bagHash,
          items: parseItemsBagGTM(gtmProps.bag),
        });
        await TagManager.initialize(tagManagerArgs);
        const currency = gtmProps.bag[0]?.price?.currency;
        const value = gtmProps.bag.map((item) => item.price.current).reduce((acc, item) => acc + item, 0);
        const coupon = getCookie(COOKIES.BONDS);
        storage.session.set(SESSION_STORAGE_KEYS.LAST_PURCHASE_BOND, coupon);
        const purchaseDataLayerGA4 = {
          event: 'begin_checkout',
          ecommerce: {
            currency,
            value,
            coupon: coupon ?? '',
            items: parseBagItemsEcommerceGA4(gtmProps.bag, gtmProps?.currentList, gtmProps?.currentListName),
          },
        };
        await initTagManager(purchaseDataLayerGA4, null);
      }
      break;
    case 'Order':
      {
        const { descripcion } = gtmProps.paymentData;
        const {
          email: infoEmail,
          name: customer_name,
          surname: customer_lastname,
          telephone: customer_phone,
          city: customer_city,
          region: customer_region,
          postalCode: customer_postcode,
          address: customer_address,
        } = gtmProps.applicant;
        const customer = email === '' ? md5(infoEmail) : email;
        const order_price =
          gtmProps.bag.length > 0 ? JSON.parse(JSON.stringify(gtmProps.bag)).reduce((accumulator, current) => accumulator + current.prices.current, 0) : 0;
        const order_price_eur =
          gtmProps.bag.length > 0 ? JSON.parse(JSON.stringify(gtmProps.bag)).reduce((accumulator, current) => accumulator + current.prices.currentEur, 0) : 0;
        const currency = gtmProps.bag.length > 0 ? gtmProps.bag[0].prices.currencySap : '';
        let order_id = '';

        if (gtmProps.locator?.locator && Array.isArray(gtmProps.locator.locator)) {
          [order_id] = gtmProps.locator.locator;
        } else if (gtmProps.locator?.locator) {
          order_id = gtmProps.locator.locator;
        }

        const mailSHA256 = SHA256(infoEmail?.toLowerCase()).toString();

        await initTagManager(
          {
            ...baseLayer,
            type: 'thank-you-page',
            items: parseItemsOrderGTM(gtmProps.bag),
            new_customer: 'no',
            order_id,
            customer,
            customer_name,
            order_amount: order_price,
            order_amount_eur: order_price_eur,
            order_currency: currency,
            payment: descripcion,
            order_email: infoEmail,

            customer_lastname,
            customer_phone,
            customer_city,
            customer_region,
            customer_postcode,
            customer_address,
          },
          null,
        );

        await initTagManager(
          {
            event: 'mailShadowed',
            mailSHA256,
          },
          null,
        );

        await TagManager.initialize(tagManagerArgs);
      }
      break;
    case 'landing':
      await initTagManager(
        {
          ...baseLayer,
          type: 'landing',
        },
        null,
      );
      await TagManager.initialize(tagManagerArgs);
      break;
    case 'Search':
      await initTagManager(
        {
          ...baseLayer,
          type: type.toLowerCase(),
        },
        null,
      );
      await TagManager.initialize(tagManagerArgs);
      break;
    case 'GlobalECheckout':
      await initTagManager(
        {
          ...baseLayer,
          type: 'checkout',
        },
        null,
      );
      await TagManager.initialize(tagManagerArgs);
      break;
    default:
      await initTagManager(
        {
          ...baseLayer,
          type: 'default',
        },
        null,
      );
      await TagManager.initialize(tagManagerArgs);
      break;
  }

  await TagManager.initialize({
    gtmId: GTM_COMMON_ID,
    customURL,
  });
}

const onMouseUpTrackable = (event) => {
  const { currentTarget, target } = event;
  const realTargetToUse = target.dataset.tracking === undefined ? currentTarget : target;

  if (target || currentTarget) {
    const tracking = target.dataset.tracking === undefined ? currentTarget.dataset.tracking : target.dataset.tracking;
    const trackingGA4 = target.dataset.trackingga4 === undefined ? currentTarget.dataset.trackingga4 : target.dataset.trackingga4;
    if (tracking) {
      const values = tracking.split('|');
      if (values.length > 0) {
        if (values.length > 3 && values[1] === 'main-menu') {
          const eventGA4 = {
            event: 'main_menu',
            click_destination: values[2],
            click_position: values[3],
          };
          dataLayerHandleEvent(eventGA4);
        }
      }
    }

    if (trackingGA4) {
      const trackedTargetData = trackingGA4.split('|');
      if (trackedTargetData.length > 2) {
        const trackable = target.dataset.trackingga4 === undefined ? currentTarget : target;
        const promotionId = trackedTargetData[0];

        // creative_slot
        const trackablesTypeList = [].slice.call(document.querySelectorAll(`[data-trackingga4$="|${trackedTargetData[2]}"]`));

        // comprobar que target esté dentro de tnm desktop o tnm mobile
        let trackablesToUse = null;
        const tnmDesktop = realTargetToUse.closest('.tnm--desktop');
        const tnmMobile = realTargetToUse.closest('.tnm--mobile');

        if (tnmDesktop) {
          trackablesToUse = tnmDesktop.querySelectorAll(`[data-trackingga4*="${SLOT_WITH_IMAGE}"]`);
        } else if (tnmMobile) {
          trackablesToUse = tnmMobile.querySelectorAll(`[data-trackingga4*="${SLOT_WITH_IMAGE}"]`);
        } else {
          const layoutContent = realTargetToUse.closest('#layoutContent');
          trackablesToUse = layoutContent.querySelectorAll(`[data-tracking*="${CAMPAIGN_ID}"]`);
        }

        const trackablesList = [].slice.call(trackablesToUse);
        const gtmType = window.gtmType || '';

        // esto que se lee de localStorage va en base al view_promotion
        // pero ese evento se basa en data-tracking NO en data-trackingga4

        /*
        const promotionItems = handleStorage('getItem', 'gtmPromotionItems');
        const parsedPromotionItems = promotionItems === undefined ? [] : JSON.parse(promotionItems);
        const selectedPromotionData = parsedPromotionItems.find((item) => item.promotion_id === promotionId);
        */

        const eventGA4 = {
          event: 'select_promotion',
          ecommerce: {
            items: [
              {
                location_id: gtmType,
                promotion_id: promotionId,
                promotion_name: trackedTargetData[1],
                creative_name: trackedTargetData[2],
                creative_slot: trackablesTypeList.indexOf(trackable) + 1,
                index: trackablesList.indexOf(trackable) + 1,
              },
            ],
          },
        };
        dataLayerHandleEvent(eventGA4);
      }
    }
  }
};

export function dataLayerTrackable(trackables) {
  for (let i = 0; i < trackables.length; i++) {
    const trackable = trackables[i];
    trackable.removeEventListener('mouseup', onMouseUpTrackable);
    trackable.addEventListener('mouseup', onMouseUpTrackable);
  }
}

export function dataLayerCartClick(product, bag, samePage = false, currentBag) {
  const { category, id, camperlab, name, code } = product?.productSheet;
  const label = `${id}${product.size ? `-${product.size}` : ''}`;

  // TODO: evento que posiblemente se pueda quitar.
  initTagManager({}, () => {
    const eventLayer = {
      event: 'addToCart',
      eventCat: 'add-to-bag',
      eventAct: 'carritoOk',
      eventLbl: label,
      evenVal: product.price.current,
      isLab: camperlab || false,
    };
    return { ...eventLayer };
  });

  initTagManager({}, () => {
    const { nameBase, prices, target, color, brand } = product.productSheet;
    const sku = code || id;

    const productCategory = category?.split('/');
    const currentPrice = prices === null ? 0 : prices?.current;
    const currentPriceEur = prices === null ? 0 : prices?.currentEur;

    const currentList = handleStorage('getItem', 'gtmCurrentList');
    const currentListUrl = handleStorage('getItem', 'gtmCurrentListUrl');
    const selectData = handleStorage('getItem', 'gtmSelectData') === undefined ? {} : JSON.parse(handleStorage('getItem', 'gtmSelectData'));
    const gtmIndex = Object.keys(selectData).includes(sku) ? selectData[sku]?.index : null;

    const currentListSplit = currentList ? currentList.split('|') : '';
    const currentListId = currentList && Array.isArray(currentListSplit) ? currentListSplit[0] : '';
    const currentListName = currentList && Array.isArray(currentListSplit) && currentList.length > 1 ? currentListSplit[1] : '';
    const lastVisitedUrl = document?.referrer ? document?.referrer.split('?')[0] : null;
    let itemListName = selectData[sku]?.item_list_name || (currentListUrl === lastVisitedUrl ? currentListName : '');
    let itemListId = selectData[sku]?.item_list_id || (currentListUrl === lastVisitedUrl && currentList ? currentListId.replaceAll(' ', '_') : '');

    if (samePage) {
      itemListName = currentListName;
      itemListId = currentList ? currentListId.replaceAll(' ', '_') : '';
    }

    let previousItems = handleStorage('getItem', 'gtmItems');

    const addToCartData = {
      item_name: brand?.toLowerCase() || name?.toLowerCase() || '',
      item_id: sku,
      price: currentPrice,
      price_eur: currentPriceEur,
      price_net: prices?.netPrice ?? 0,
      discount: getAppliedDiscount(prices),
      discount_eur: prices?.discountEur || 0,
      currency: prices?.currencySap || '',
      item_brand: IS_CAMPER ? 'camper' : 'nnormal',
      item_category: target in categories ? categories[target] : '', // 'women
      item_category2: productCategory.length >= 2 ? productCategory[1].toLowerCase() : '', // shoes
      item_category3: productCategory.length >= 2 ? productCategory[2].toLowerCase() : '', // sandals
      item_category4: nameBase?.toLowerCase() || '', // <linea>
      item_category5: product?.size || '', // ?productCategory && Array.isArray(productCategory) ? productCategory.pop() : '', // DISCOUNT-....
      item_variant: color?.toLowerCase() || '',
      item_list_name: itemListName || null,
      item_list_id: itemListId?.toLowerCase() || null,
      item_size: product?.size || '',
      quantity: 1,
      index: gtmIndex ? Number(gtmIndex) : 1,
    };

    if ([undefined, null].includes(previousItems)) {
      previousItems = {};
    } else {
      previousItems = JSON.parse(previousItems);
    }

    // guardamos toda la info que guardamos en momento de add to cart
    handleStorage(
      'setItem',
      'gtmItems',
      JSON.stringify({
        ...previousItems,
        [sku]: addToCartData,
      }),
    );
    const fullBag = currentBag?.length > 0 ? [...parseBagItemsEcommerceGA4(currentBag), addToCartData] : [addToCartData];

    const eventLayer = {
      event: 'add_to_cart',
      id_bag: bag?.hashOrderId || '',
      ecommerce: {
        value: currentPrice,
        currency: prices?.currencySap || '',
        items: [addToCartData],
      },
      cart: {
        items: fullBag,
      },
    };
    return { ...eventLayer };
  });
}

export function dataLayerCartClickV2({ data = [], addedIds = [], bagId, currency = 'EUR' }) {
  const addedItems = data
    .filter((cartItem) => addedIds.includes(cartItem.id))
    .map((cartItem) => {
      const { name, productId, price: prices, category, target, color, size } = cartItem;
      const currentPrice = prices === null ? 0 : prices?.current;
      const currentPriceEur = prices === null ? 0 : prices?.currentEur;
      const productCategory = category?.split('/');
      const currentList = handleStorage('getItem', 'gtmCurrentList');
      const currentListSplit = currentList ? currentList.split('|') : '';
      const currentListId = currentList && Array.isArray(currentListSplit) ? currentListSplit[0] : '';
      const currentListName = currentList && Array.isArray(currentListSplit) && currentList.length > 1 ? currentListSplit[1] : '';
      const currentListUrl = handleStorage('getItem', 'gtmCurrentListUrl');
      const lastVisitedUrl = document?.referrer ? document?.referrer.split('?')[0] : null;
      const gtmIndex = handleStorage('getItem', 'gtmCurrentItemIndex');
      const itemListName = currentListUrl === lastVisitedUrl ? currentListName : '';
      const itemListId = currentListUrl === lastVisitedUrl && currentList ? currentListId.replaceAll(' ', '_') : '';

      return {
        item_name: name?.toLowerCase() || '',
        item_id: productId,
        price: currentPrice,
        price_eur: currentPriceEur,
        price_net: prices?.netPrice ?? 0,
        discount: getAppliedDiscount(prices),
        discount_eur: prices?.discountEur || 0,
        item_brand: IS_CAMPER ? 'camper' : 'nnormal',
        item_category: target in categories ? categories[target] : '', // 'women
        item_category2: productCategory.length >= 2 ? productCategory[1].toLowerCase() : '', // shoes
        item_category3: productCategory.length >= 2 ? productCategory[2].toLowerCase() : '', // sandals
        item_category4: productCategory.length >= 3 ? productCategory[3].toLowerCase() : '', // <linea>
        item_category5: size || '', // productCategory && Array.isArray(productCategory) ? productCategory.pop() : '', // DISCOUNT-....
        item_variant: color?.toLowerCase() || '',
        item_list_name: itemListName || null,
        item_list_id: itemListId?.toLowerCase(),
        item_size: size || '',
        quantity: 1,
        index: gtmIndex ? Number(gtmIndex) : 1,
      };
    });

  const fullBag = parseBagItemsEcommerceGA4(data);
  const addToCartEvent = {
    event: 'add_to_cart',
    id_bag: bagId,
    ecommerce: {
      currencyCode: currency,
      currency: currency,
      value: addedItems.reduce((acc, item) => acc + item.price, 0),
      items: addedItems,
    },
    cart: {
      items: fullBag,
    },
  };
  dataLayerHandleEvent(addToCartEvent);
}

/**
 * @param {string} code
 */
export function track3D(code) {
  const event = {
    event: 'item_photos',
    event_category: 'product detail',
    event_detail_1: '3d',
    event_detail_2: code,
  };
  dataLayerHandleEvent(event);
}

export function trackCaptchaPresence() {
  dataLayerHandleEvent({
    event: 'impression_captcha',
    event_category: 'captcha',
  });
}

export function trackCaptchaSuccess(success) {
  dataLayerHandleEvent(
    success ?
      {
        event: 'success_captcha',
        event_category: 'captcha',
      }
    : {
        event: 'error_captcha',
        event_category: 'captcha',
      },
  );
}
