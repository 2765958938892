const currentDomain = process.env.NEXT_PUBLIC_DOMAIN;

function getDomainOld() {
  if (['camper', 'nnormal', 'camperlab'].includes(currentDomain)) {
    return currentDomain;
  }
  return 'camper';
}

/**
 * temporal hasta que podamos pasar helpers.js a TS
 */
function getDomainValueOld(args) {
  const domain = getDomainOld();
  let domainToUse = domain;

  if (typeof args[domain] === 'string' && ['camper', 'nnormal', 'camperlab'].includes(args[domain])) {
    domainToUse = args[domain];
  }

  return args[domainToUse] ?? null;
}

module.exports = { getDomainValueOld };
