const { IS_CAMPER } = require('./system');

const DEFAULT_VALUES_FOR_GIFTCARD = [50, 100, 150, 200, 250];

const FAMILIES = {
  SHOES: 1,
  ACCESSORIES: 2,
  CLOTHES: 5,
  GOODS: 6,
};

const FAMILIES_UNBXD = {
  shoes: '1',
  accessories: '2',
  clothes: '5',
  goods: '6',
};

const GIFT_MIN_TOTAL_PRICE = 150;

const JUNCTIONS_TOE_CAPS_SKU = ['KS00063-001', 'KS00063-002', 'KS00063-003', 'KS00063-004', 'KS00063-005'];

const LOW_STOCK_QUANTITY = 1;

const MAX_BAG_LENGTH = 10;

const NOT_FOUND_GIFTCARD = '000000000000';

const SOCKS_TYPE = 'TALLAP';

const TARGETS = {
  MEN: 'M',
  WOMEN: 'W',
  KIDS: 'K',
  GOODS: 'G',
  UNISEX: 'X',
};

const TARGETS_TRANSLATIONS = {
  W: ['generico', 'target.mujer', 'Women'],
  M: ['generico', 'target.hombre', 'Men'],
  K: ['generico', 'target.kids', 'Kids'],
  G: ['generico', 'target.goods', 'Goods'],
  X: ['generico', 'target.unisex', 'Unisex'],
  A: ['buscador', 'prefiltro.todos', 'See all'],
};

const TARGETS_UNBXD = {
  K: 'Kids',
  M: 'Men',
  W: 'Women',
};

const UNISEX_PRODUCT_CODES = ['K100839-001', 'K200155-018', 'K100839-003', 'K200155-020', 'K100839-002', 'K200155-019', 'K100839-006', 'K200155-026'];

const GIFT_BAG_BASE = `https://www.${IS_CAMPER ? 'camper' : 'nnormal'}.com/html/GWP`;

const PREVOUCHER_FIXED_COLLECTIONS = [
  'pbf',
  'pbfw',
  'pbfm',
  'pbfk',
  'pbfa',
  'pbfap',
  'pprslwa',
  'pprslma',
  'pprslka',
  'pprslwag',
  'pprslmag',
  'pprslkag',
  'pprslaag',
  'pprslapag',
];

module.exports = {
  DEFAULT_VALUES_FOR_GIFTCARD,
  FAMILIES,
  FAMILIES_UNBXD,
  GIFT_MIN_TOTAL_PRICE,
  JUNCTIONS_TOE_CAPS_SKU,
  LOW_STOCK_QUANTITY,
  MAX_BAG_LENGTH,
  NOT_FOUND_GIFTCARD,
  SOCKS_TYPE,
  TARGETS,
  TARGETS_TRANSLATIONS,
  TARGETS_UNBXD,
  UNISEX_PRODUCT_CODES,
  GIFT_BAG_BASE,
  PREVOUCHER_FIXED_COLLECTIONS,
};
