import type { ApiKO, ApiOK, ApiResponse, ApiResponseError } from 'types/api';

const defaultError: ApiResponseError = { code: 'unknown_error', message: 'unknown error' };
const API_GATEWAY = process.env.API_GATEWAY;

export function isDefaultError(error: ApiResponseError) {
  return error.code === defaultError.code;
}

export function getServerError(data?: ApiKO) {
  if (Array.isArray(data?.errors) && data?.errors?.length > 0) {
    return data.errors[0];
  }
  return defaultError;
}

export function checkForServerError(r: ApiResponse<{}>) {
  if (typeof r === 'object' && r !== null) {
    return 'status' in r && r.status === 'KO';
  }
  return false;
}
type HandleFetchResponseReturn<T> = Promise<{ ok: true; json: ApiOK<T> } | { ok: false; error: ApiResponseError }>;

export async function handleFetchResponse<T>(response: Response): HandleFetchResponseReturn<T> {
  const isJson = response.headers.get('content-type')?.includes('application/json');

  if (!isJson) {
    return { ok: false, error: { code: 'json_error', message: 'response was not json' } };
  }
  if (!response.ok) {
    let error = await response.json();
    error = getServerError(error);
    return { ok: false, error };
  }
  let json = null;
  try {
    json = (await response.json()) as T;
  } catch (e) {
    return { ok: false, error: { code: 'json_error', message: 'response was not json' } };
  }
  if (checkForServerError(json)) {
    return { ok: false, error: getServerError(json) };
  }
  return { ok: true, json };
}

type CreateRequestUrlParams = {
  path: string;
  base?: string;
  query?: Record<string, string>;
};

export function createRequestUrl({ path, base = API_GATEWAY, query = {} }: CreateRequestUrlParams) {
  const url = new URL(base + path);
  Object.keys(query).forEach((key) => url.searchParams.append(key, query[key]));
  return url;
}

const defaultHeaders = {
  'Content-Type': 'application/json',
};

export function createRequestHeaders(init?: HeadersInit): Headers {
  const headers = new Headers(init ?? defaultHeaders);
  return headers;
}

/*
 * @param {string | null} accept
 * @returns {{locale: string | null, language: string | null, country: string | null}}
 */
export function parseAcceptLanguageHeader(accept?: string | null) {
  if (accept === null || accept === undefined) {
    return {
      locale: null,
      language: null,
      country: null,
    };
  }
  const acceptString = accept.split(';')[0];

  const localeMatch = new RegExp(/([a-z]){2}-[A-Z]{2}?/).exec(acceptString);
  if (Array.isArray(localeMatch)) {
    const [language, country] = localeMatch[0].split('-');
    return {
      locale: localeMatch[0],
      language,
      country,
    };
  }
  // if we only find language in the accept string
  const languageMatch = new RegExp(/([a-z]){2}/).exec(acceptString);
  if (Array.isArray(languageMatch)) {
    return {
      locale: null,
      language: languageMatch[0],
      country: null,
    };
  }

  return {
    locale: null,
    language: null,
    country: null,
  };
}
