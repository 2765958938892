const isNull = (str) => str === undefined || str === null;

const isBlank = (str) => str === undefined || str === null || str.trim() === '';

const getIfBlank = (str, defaultValue) => {
  if (isBlank(str)) {
    return isNull(defaultValue) ? defaultValue : defaultValue.trim();
  }
  return str.trim();
};

const normalizeString = (inputString) => {
  if (typeof inputString !== 'string') return '';
  // remove all accents and weird characters from string
  let cleanString = inputString.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  cleanString = cleanString.replace(/[^a-zA-Z0-9\s]/g, '');
  // el regex anterior limpia todos los characteres que no sean alfanumericos o espacios, si nos quedamos con una string vacia simplemente volvemos a la string original
  if (cleanString === '') return inputString.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  return cleanString;
};

const camelCaseToRegularString = (inputString) => {
  let regularString = inputString.replace(/([a-z])([A-Z])/g, '$1 $2');
  regularString = regularString.toLowerCase().trim();
  return regularString;
};

function interpolate(str, obj) {
  if (typeof str !== 'string') {
    return '';
  }
  let copy = str;
  Object.entries(obj).forEach(([key, value]) => {
    // TODO: replace with replaceAll after nodeJs upgrade
    // use regex to replace all instances of key with value
    copy = copy.replace(new RegExp(key, 'g'), value ?? '');
  });
  return copy;
}
// help for removing diacriticals https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript?page=1&tab=scoredesc#tab-top
/**
 * @type {function(?): string}
 */
function removeStrangeCharacters(inputString) {
  if (typeof inputString !== 'string') return '';
  // remove all accents and weird characters from string
  return inputString.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  // return inputString.normalize('NFD').replace(/\p{Diacritic}/gu, '');
}
const fakeBase = 'https://fake.url.com';

function normalizeUrl(s, { removeQuery = true } = {}) {
  if (typeof s !== 'string') return '';
  const url = new URL(s, fakeBase);
  let { pathname } = url;

  // decodeURIComponent

  pathname = pathname
    .trim()
    .split('/')
    .filter(Boolean)
    .map((part) => {
      if (part.match(/([a-z]{2}_[A-Z]{2})/g)) {
        return part;
      }
      part = decodeURIComponent(part);
      part = removeStrangeCharacters(part);
      part = part.replace(/\s+/g, '_');
      return part;
    })
    .join('/');
  return url.origin === fakeBase ? `/${pathname}${removeQuery ? '' : url.search}` : `${url.origin}/${pathname}${removeQuery ? '' : url.search}`;
}

function hasNonAlphaCharacters(str) {
  if (typeof str !== 'string') return false;
  return /[^a-zA-Z]/g.test(str);
}

function capitalizeFirstLetters(string) {
  if (typeof string !== 'string') return '';
  return string.replace(/(?:^|\s)\S/g, (a) => a.toUpperCase());
}

module.exports = {
  isNull,
  isBlank,
  getIfBlank,
  normalizeString,
  camelCaseToRegularString,
  interpolate,
  removeStrangeCharacters,
  normalizeUrl,
  hasNonAlphaCharacters,
  capitalizeFirstLetters,
};
