import { get, post } from 'utils/apiWrapper';
import { prepareHeadersFromContext, DEFAULT_LIMIT } from 'utils/helpers';
import { SEARCH_URLS, COMPLETE_LOOK, SAVE_IMAGE, RETRIEVE_IMAGE } from 'utils/routes';

import { getDomainValue } from 'utils/domain';

export const searchProductsUnbxd = ({ lang, market, query, filter = '', sort, page, uid, ipAddress, translations }) =>
  new Promise((resolve, reject) => {
    // translations tiene que existir y tener 2 valores diferentes de '' siempre
    // ya lo comprobamos para setear el booleano de searchNew pero por si acaso
    if (!translations || !Array.isArray(translations) || translations.length !== 2 || translations.some((item) => item === '')) {
      console.error('Missing required keys for unbxd API.');
      reject(translations);
    }
    const fields = [
      `${market}_currency_unx_ts`,
      `${market}_is_on_sale_unx_ts`,
      `${market}_max_price_EUR_unx_d`,
      `${market}_max_price_unx_d`,
      `${market}_min_price_EUR_unx_d`,
      `${market}_min_price_unx_d`,
      `${market}_max_sale_price_unx_d`,
      `${market}_min_sale_price_unx_d`,
      `${market}_max_sale_price_EUR_unx_d`,
      'age_group',
      'title',
      `${market}_${lang.toLowerCase()}_productUrl`,
      'material',
      'is_shooting_new',
      'color',
      'gender',
      `${lang.toLowerCase()}_target`,
      'grid_image_suffix',
      `${market}_voucher_prelaunch`,
      `${market}_voucher_code`,
      `${market}_voucher_type`,
      `${market}_voucher_campaign_type`,
      `${market}_voucher_autoapply`,
      `${market}_sale_percentage`,
      `${market}_voucher_discount_unx_ts`,
      `${market}_max_voucher_price_unx_ts`,
      `${market}_min_voucher_price_unx_ts`,
      'brand',
    ];
    const sizesFacet = `&facet.field=${market}_available_sizes_unx_tsm&f.${market}_available_sizes_unx_tsm.facet.sort=index&f.${market}_available_sizes_unx_tsm.facet.limit=50&f.${market}_available_sizes_unx_tsm.facet.display.name=Size&f.${market}_available_sizes_unx_tsm.facet.position=4`;
    const sortMap = {
      price_asc: 'asc',
      price_desc: 'desc',
    };
    let querySort = '';
    if (sort && sort.length && sort !== 'default') {
      querySort = `&sort=${market}_min_price_EUR_unx_d%20${sortMap[sort]}`;
    }
    const baseApiKeyUnbxd = `/${translations.join('/')}/`;
    const path = `${baseApiKeyUnbxd}search?q=${encodeURIComponent(query)}&variants=false&facet.multiselect=false${sizesFacet}&filter=brand_uFilter:${getDomainValue({ camper: '(CAMPER OR CAMPERLAB)', nnormal: 'NNORMAL' })}&filter=available_in:${market}${filter}&fields=${fields.join(',')}&rows=${DEFAULT_LIMIT}&page=${page}${querySort}${uid ? `&uid=${uid}` : ''}`;
    const rqHeaders = {
      'unbxd-user-id': uid,
    };
    if (ipAddress) {
      rqHeaders['X-forwarded-for'] = ipAddress;
    }
    get({ path, base: 'https://search.unbxd.io', headers: rqHeaders, skipHeaders: true })
      .then((res) => {
        const unbxdRequestId = res?.headers ? res?.headers['unx-request-id'] || '' : '';
        const { data } = res;
        const { response, facets, searchMetaData, redirect } = data;
        if (!response) {
          resolve(false);
        }
        const { products, numberOfProducts } = response;
        let fallback = null;
        if (searchMetaData.fallback && Object.keys(searchMetaData.fallback).length > 0) {
          fallback = {
            query: searchMetaData.fallback.q,
            original: searchMetaData.fallback.original.q,
          };
        }
        return resolve({
          products,
          unbxd: true,
          facets,
          numberOfProducts,
          unbxdRequestId,
          fallback,
          redirect: redirect ?? null,
        });
      })
      .catch(reject);
  });

/** @deprecated */
export const searchUrls = ({ context, profile, searchValue = '' }) =>
  new Promise((resolve, reject) => {
    const path = `${SEARCH_URLS}?profile=${profile}`;
    const headers = prepareHeadersFromContext(context);

    get({ path, headers })
      .then(({ data }) => {
        const { status } = data;

        if (status === 'OK') {
          const urls = data && data.urls !== undefined && data.urls !== null ? data.urls : [];
          const foundUrl = urls.find((url) => url.value.toLowerCase() === searchValue.trim().toLowerCase())?.url ?? null;
          return resolve(foundUrl);
        }

        return reject(data);
      })
      .catch(reject);
  });

export async function autosuggestUnbxd(lang, market, query, translations, uid) {
  // translations tiene que existir y tener 2 valores diferentes de '' siempre
  // ya lo comprobamos para setear el booleano de searchNew pero por si acaso
  try {
    if (!translations || !Array.isArray(translations) || translations.length !== 2 || translations.some((item) => item === '')) {
      console.error('Missing required keys for unbxd API.');
      return false;
    }

    const isBlankSearch = query === '*';
    const fields = [
      'title',
      'line',
      'imageUrl',
      'image_url',
      'salePrice',
      `${market}_${lang.toLowerCase()}_productUrl`,
      'price',
      `${market}_max_price_unx_d`,
      `${market}_max_sale_price_unx_d`,
      'currency',
      `${market}_currency_unx_ts`,
      `${lang.toLowerCase()}_product_sheet_h1`,
      'uniqueId',
      'id',
      'autosuggest',
      'doctype',
      'product_sheet_H1',
      `${market}_sale_percentage`,
      'age_group',
      'material',
      'is_shooting_new',
      'color',
      'gender',
      `${lang.toLowerCase()}_target`,
      `${market}_voucher_prelaunch`,
      `${market}_voucher_code`,
      `${market}_voucher_type`,
      `${market}_voucher_campaign_type`,
      `${market}_voucher_autoapply`,
      `${market}_voucher_discount_unx_ts`,
      `${market}_max_voucher_price_unx_ts`,
      `${market}_min_voucher_price_unx_ts`,
      'brand',
    ];

    const config = {
      version: 'V2',
      'inFields.count': 0,
      'popularProducts.count': 6,

      'topQueries.count': 10,
      'topQueries.sort': 'clicks_unbxd_double desc',

      'keywordSuggestions.count': isBlankSearch ? 0 : 2,
      'promotedSuggestion.count': isBlankSearch ? 5 : 3,
      'promotedSuggestion.sort': 'promoted_suggestion_priority_unbxd_double desc',

      'popularProducts.fields': fields.join(','),
      'popularProducts.filter': `available_in:"${market}"`,
      variants: false,
      fallback: true,
      'fallback.method': 'fuzzySearch',
      indent: 'off',
    };

    if (!isBlankSearch) {
      config['keywordSuggestions.sort'] = 'result_unbxd_double desc';
    }

    if (uid) {
      config.uid = uid;
    }

    const baseApiKeyUnbxd = `/${translations.join('/')}/`;
    const configString = new URLSearchParams(config).toString();
    const path = `${baseApiKeyUnbxd}autosuggest?q=${encodeURIComponent(query)}&${configString}&filter=brand_uFilter:${getDomainValue({ camper: '(CAMPER OR CAMPERLAB)', nnormal: 'NNORMAL' })}`;

    const rqHeaders = {
      'unbxd-user-id': uid,
    };

    const result = await get({ path, base: 'https://search.unbxd.io', headers: rqHeaders, skipHeaders: true });
    const unbxdRequestId = result?.headers ? result?.headers['unx-request-id'] || '' : '';
    const { data } = result;
    const { response, facets, searchMetaData } = data;

    if (!response) {
      return false;
    }

    const { products, numberOfProducts } = response;
    let fallback = null;
    if (searchMetaData.fallback && Object.keys(searchMetaData.fallback).length > 0) {
      fallback = {
        query: searchMetaData.fallback.q,
        original: searchMetaData.fallback.original.q,
      };
    }
    return {
      products: products?.filter((item) => item.result_unbxd_double !== 0),
      unbxd: true,
      facets,
      numberOfProducts,
      unbxdRequestId,
      fallback,
    };
  } catch (e) {
    console.error(e);
    return false;
  }
}

export async function completeLookUnbxd({ profile, image }) {
  try {
    const formData = new FormData();
    formData.append('profile', profile);
    formData.append('image', image);

    const completeLookResponse = await post({
      path: COMPLETE_LOOK,
      data: formData,
    });

    const { status, valid, query, errors } = completeLookResponse.data;

    if (status === 'OK') {
      return { valid, query };
    } else {
      return { valid: false, query, errors };
    }
  } catch (e) {
    console.error(e);
    return { valid: false, query: '' };
  }
}

export async function searchByImageUnbxd({ lang, market, image, translations, filter = '', uid, sort }) {
  try {
    if (!image) {
      console.error('Missing image, this should not happen.');
      return false;
    }

    const fields = [
      `${market}_currency_unx_ts`,
      `${market}_is_on_sale_unx_ts`,
      `${market}_max_price_EUR_unx_d`,
      `${market}_max_price_unx_d`,
      `${market}_min_price_EUR_unx_d`,
      `${market}_min_price_unx_d`,
      `${market}_max_sale_price_unx_d`,
      `${market}_min_sale_price_unx_d`,
      `${market}_max_sale_price_EUR_unx_d`,
      'age_group',
      'title',
      `${market}_${lang.toLowerCase()}_productUrl`,
      'material',
      'is_shooting_new',
      'color',
      'gender',
      `${lang.toLowerCase()}_target`,
      'grid_image_suffix',
      `${market}_voucher_prelaunch`,
      `${market}_voucher_code`,
      `${market}_voucher_type`,
      `${market}_voucher_campaign_type`,
      `${market}_voucher_autoapply`,
      `${market}_sale_percentage`,
      `${market}_voucher_discount_unx_ts`,
      `${market}_max_voucher_price_unx_ts`,
      `${market}_min_voucher_price_unx_ts`,
      'uniqueId',
      'imageUrl',
    ];
    const sortMap = {
      price_asc: 'asc',
      price_desc: 'desc',
    };

    let querySort = '';
    if (sort && sort.length && sort !== 'default') {
      querySort = `&sort=${market}_min_price_EUR_unx_d%20${sortMap[sort]}`;
    }

    const siteKey = translations[1];
    const baseApiKeyUnbxd = `/${siteKey}/`;
    const path = `${baseApiKeyUnbxd}images?rows=${DEFAULT_LIMIT}&start=0&variants=false&facet.multiselect=false&facet.field=${market}_available_sizes_unx_tsm&f.${market}_available_sizes_unx_tsm.facet.sort=index&f.${market}_available_sizes_unx_tsm.facet.limit=50&f.${market}_available_sizes_unx_tsm.facet.display.name=Size&filter=available_in:${market}${filter}&fields=${fields.join(',')}&popularProducts.filter=brand_uFilter:${getDomainValue({ camper: '(CAMPER OR CAMPERLAB)', nnormal: 'NNORMAL' })}${querySort}`;

    const [dataType, base64Image] = image.split(',');
    const [, contentType] = dataType.split(':');
    const rqHeaders = {
      Authorization: translations[0],
      'unbxd-user-id': uid,
      'Content-Type': contentType,
    };

    const payload = {
      variants: false,
      'facet.multiselect': true,
      'facet.field': `${market}_available_sizes_unx_tsm`,
      [`f.${market}_available_sizes_unx_tsm.facet.sort`]: 'index',
      [`f.${market}_available_sizes_unx_tsm.facet.limit`]: 50,
      [`f.${market}_available_sizes_unx_tsm.facet.display.name`]: 'Size',
      [`f.${market}_available_sizes_unx_tsm.facet.position`]: 4,
      filter: `available_in:${market}${filter}`,
      fields: fields.join(','),
      rows: DEFAULT_LIMIT,
    };

    if (uid) {
      payload.uid = uid;
    }

    const result = await post({ path, base: 'https://search.unbxd.io/v2.0/sites', headers: rqHeaders, data: base64Image, skipHeaders: true });

    const unbxdRequestId = result?.headers ? result?.headers['unx-request-id'] || '' : '';
    const { data } = result;
    const { response, facets, searchMetaData } = data;

    if (!response) {
      return false;
    }

    const { products, numberOfProducts } = response;
    let fallback = null;
    if (searchMetaData.fallback && Object.keys(searchMetaData.fallback).length > 0) {
      fallback = {
        query: searchMetaData.fallback.q,
        original: searchMetaData.fallback.original.q,
      };
    }

    return {
      products,
      facets: facets || {},
      numberOfProducts,
      unbxdRequestId,
      fallback,
    };
  } catch (e) {
    console.error(e);
    return false;
  }
}

export async function saveImageForSearch({ profile, encodedImage }) {
  const result = await post({
    path: SAVE_IMAGE,
    data: {
      profile,
      encodedImage,
    },
  });

  const { data } = result;

  if (data.status === 'OK') {
    return data.id;
  }

  return false;
}

export async function retrieveImageForSearch({ profile, id, context }) {
  const headers = prepareHeadersFromContext(context);
  const path = `${RETRIEVE_IMAGE}?profile=${profile}&id=${id}`;

  const result = await get({ path, headers });
  const { data } = result;

  if (data.status === 'OK' && data.encodedImage) {
    return data.encodedImage;
  }

  return false;
}
