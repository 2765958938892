// https://gist.github.com/jamesbar2/1c677c22df8f21e869cca7e439fc3f5b

const { parseEmail } = require('./validation');

// https://stackoverflow.com/questions/578406/what-is-the-ultimate-postal-code-and-zip-regex/7185241#7185241
const { postalCodePattern } = require('./helpers');

// FIXME: Por qué no se usan los patrones?
const CVC_PATTERN = (isAMEB) => ({
  MX: RegExp(`^(?!000)d{${isAMEB ? 4 : 3}}$`),
  default: RegExp(`^d{${isAMEB ? 4 : 3}}$`),
});

const CC_PATTERN = {
  JCBB: RegExp('^(?:2131|1800|35)[0-9]{0,}$'), // 2131, 1800, 35 (3528-3589)
  AMEB: RegExp('^3[47][0-9]{0,}$'), // 34, 37
  DINB: RegExp('^3(?:0[0-59]{1}|[689])[0-9]{0,}$'), // 300-305, 309, 36, 38-39
  VISB: RegExp('^4[0-9]{0,}$'), // 4
  MSCB: RegExp('^(5[1-5]|222[1-9]|22[3-9]|2[3-6]|27[01]|2720)[0-9]{0,}$'), // 2221-2720, 51-55
  MASB: RegExp('^(5[06789]|6)[0-9]{0,}$'),
};

module.exports = {
  simpleEmailRequired: (t) => [
    {
      required: true,
      message: t('validation', 'required.email', 'Please input your email'),
    },
  ],
  email: (t) => [
    {
      required: true,
      message: t('validation', 'required.email', 'Please input your email'),
    },
    {
      type: 'email',
      message: <div dangerouslySetInnerHTML={{ __html: t('compra.formulario', 'error.campos.mail') }}></div>,
    },
    {
      type: 'string',
    },
    () => ({
      warningOnly: true,
      validator(_, value) {
        if (value !== '' && !parseEmail(value).success) {
          return Promise.reject(new Error(t('validation', 'invalid.email', 'Please input a valid email')));
        }

        return Promise.resolve();
      },
    }),
  ],
  telephone: (t) => [
    {
      required: true,
      message: t('validation', 'required.phone', 'Please input your phone'),
    },
    {
      type: 'string',
      // pattern: /^[679]{1}[0-9]{8}$/,
      pattern: /^[0-9]{1,}$/,
      message: t('validation', 'invalid.phone', 'Please input a valid phone!'),
    },
  ],
  notRequiredTelephone: (t) => [
    {
      type: 'string',
      // pattern: /^[679]{1}[0-9]{8}$/,
      required: false,
      pattern: /^[0-9]{1,}$/,
      message: t('validation', 'invalid.phone', 'Please input a valid phone!'),
    },
  ],
  noStrictTelephone: (t) => [
    {
      required: true,
      message: t('validation', 'required.phone', 'Please input your phone'),
    },
    {
      type: 'string',
      pattern: /^[0-9]{1,}$/,
      message: t('validation', 'nostrict.phone', 'Please provide a telephone number where you can be reached if we need to contact you'),
    },
  ],
  checkoutTelephone: (t) => [
    {
      required: true,
      message: t('validation', 'required.phone', 'Please input your phone'),
    },
    {
      type: 'string',
      pattern: /^[0-9]{5,16}$/,
      message: t('validation', 'nostrict.phone', 'Please provide a telephone number where you can be reached if we need to contact you'),
    },
  ],
  addresNumstrasse: (t) => [
    {
      required: true,
      message: t('compra.formulario', 'error.campos.direccion.de'),
      markets: ['DE'],
    },
  ],
  numstrasse: (t) => [
    {
      required: true,
      message: t('compra.formulario', 'error.campos.direccion.de'),
      markets: ['DE'],
    },
  ],
  postalCode: (t, locale) => [
    {
      required: true,
      message: t('validation', 'required.postal', 'Please input your postal code'),
    },
    {
      type: 'string',
      pattern: postalCodePattern[locale.split('_')[1]] || /^[A-Z|0-9]+((-|\s)[A-Z|0-9]+)*$/,
      message: t('validation', 'invalid.postal', 'Please input a valid postal code'),
    },
  ],
  postalCodeOrder: (t, locale) => [
    {
      required: true,
      message: `${t('compra.formulario', 'error.campos.cp', 'Please input your postal code')} ${t('compra.formulario', `error.campos.cp.${locale.split('_')[1]}`, '')}`,
    },
    {
      type: 'string',
      pattern: postalCodePattern[locale.split('_')[1]] || /^[A-Z|0-9]+((-|\s)[A-Z|0-9]+)*$/,
      message: `${t('compra.formulario', 'error.campos.cp', 'Please input your postal code')} ${t('compra.formulario', `error.campos.cp.${locale.split('_')[1]}`, '')}`,
    },
  ],
  name: (t) => [
    {
      required: true,
      message: t('compra.formulario', 'error.campos.nombre', 'Please input your name'),
    },
    {
      type: 'string',
      pattern: /^(.){2,}$/,
      message: t('compra.formulario', 'error.campos.nombre', 'Please input your name'),
    },
  ],
  nameAsia: (t) => [
    {
      required: true,
      message: t('compra.formulario', 'error.campos.nombre', 'Please input your name'),
      markets: ['TW', 'TH', 'KR', 'JP', 'HK'],
    },
    {
      type: 'string',
      pattern: /^(.){1,}$/,
      message: t('compra.formulario', 'error.campos.nombre', 'Please input your name'),
    },
  ],
  requiredField: (t) => [
    {
      required: true,
      message: t('validation', 'required.field', 'this field is required'),
    },
  ],
  surname: (t) => [
    {
      required: true,
      message: t('compra.formulario', 'error.campos.apellidos', 'Please input your surname'),
    },
    {
      type: 'string',
      pattern: /^(.){2,}$/,
      message: t('compra.formulario', 'error.campos.nombre', 'Please input your name'),
    },
  ],
  address: (t) => [
    {
      required: true,
      message: t('validation', 'required.address', 'Please input your address'),
    },
    {
      type: 'string',
    },
  ],
  addressLatinAlphabet: (t) => [
    {
      required: true,
      message: t('validation', 'required.address', 'Please input your address'),
    },
    {
      type: 'string',
      pattern: /^([.,\-0-9A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/,
      message: t('validation', 'format.field.generic', 'Incorrect format'),
    },
  ],
  addressOrder: (t) => [
    {
      required: true,
      message: t('compra.formulario', 'error.campos.direccion', 'Please provide the address where you would like to receive your purchase'),
    },
    {
      type: 'string',
    },
  ],
  bankIdeal: (t) => [
    {
      required: true,
      message: t('compra.formulario', 'info.banco.ideal'),
    },
  ],
  city: (t) => [
    {
      required: true,
      message: t('validation', 'required.city', 'Please input your city'),
    },
    {
      type: 'string',
      pattern: /^(.){2,}$/,
      message: t('validation', 'required.city', 'Please input your city'),
    },
  ],
  colonia: (t) => [
    {
      required: true,
      message: t('compra.formulario', 'error.campos.colonia', 'Please indicate the Colonia of your address'),
      markets: ['MX'],
    },
  ],
  cityOrder: (t) => [
    {
      required: true,
      message: t('compra.formulario', 'error.campos.ciudad', 'Please input your city'),
    },
    {
      type: 'string',
      pattern: /^(.){2,}$/,
      message: t('validation', 'required.city', 'Please input your city'),
    },
  ],
  region: (t) => [
    {
      required: true,
      message: t('validation', 'required.province', 'Please input your province'),
    },
  ],
  regionOrder: (t) => [
    {
      required: true,
      message: t('compra.formulario', 'error.campos.provincia', 'Please input your province'),
    },
  ],
  country: (t) => [
    {
      required: true,
      message: t('validation', 'required.country', 'Please input your country'),
    },
  ],
  paymentType: (t) => [
    {
      required: true,
      message: t('validation', 'required.payment', 'Please select a payment option'),
    },
  ],
  password: (t) => [
    {
      required: true,
      message: t('validation', 'required.password', 'Please input your password'),
    },
  ],
  passwordRepeat: (t) => [
    {
      required: true,
      message: t('validation', 'confirm.password', 'Please confirm your password'),
    },
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (!value || getFieldValue('password') === value) {
          return Promise.resolve();
        }

        return Promise.reject(new Error(t('validation', 'password.no.match', 'The two passwords that you entered do not match')));
      },
    }),
  ],
  privacyPolicy: (t) => [
    {
      validator: (_, value) =>
        value ? Promise.resolve() : Promise.reject(new Error(t('validation', 'accept.policy', 'You must accept the terms and conditions'))),
    },
  ],
  koreaCheck: (t) => [
    {
      validator: (_, value) => (value ? Promise.resolve() : Promise.reject(new Error(t('validation', 'accept.policy', 'You must accept this condition')))),
      markets: ['KR'],
    },
  ],
  day: (t) => [
    {
      required: true,
      message: t('validation', 'select.day', 'Please select a day'),
    },
  ],
  month: (t) => [
    {
      required: true,
      message: t('validation', 'select.month', 'Please select a month'),
    },
  ],
  giftCard:
    (t) =>
    (minAmount = 1, maxAmount) => [
      {
        required: true,
        message: t('validation', 'required.generic', 'Please fill this input'),
      },
      {
        validator: (_, value) =>
          value && value >= minAmount && value <= maxAmount ?
            Promise.resolve()
          : Promise.reject(new Error(`Value must be between ${minAmount} and ${maxAmount}`)),
      },
    ],
  textArea: (t) => [
    {
      required: true,
      message: t('validation', 'required.generic', 'Please fill this input'),
    },
  ],
  emailRepeat: (t) => [
    {
      required: true,
      message: t('validation', 'required.email', 'Please input your email'),
    },
    {
      type: 'email',
      message: t('compra.formulario', 'error.campos.mail'),
    },
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (!value || getFieldValue('email') === value) {
          return Promise.resolve();
        }

        return Promise.reject(new Error(t('validation', 'email.no.match', 'Emails do not match')));
      },
    }),
  ],
  cardNumber: (t) => [
    {
      required: true,
      message: t('validation', 'required.field.generic', 'Please, fill this field.'),
    },
    {
      type: 'string',
      pattern: /^([0-9]{4})(-)?([0-9]{4})(-)?([0-9]{4})$/, // xxxx-xxxx-xxxx format
      message: t('validation', 'format.field.generic', 'Incorrect format'),
    },
  ],
  cardPin: () => [
    // {
    //   required: true,
    //   message: t('validation', 'required.field.generic', 'Please, fill this field.'),
    // },
  ],
  postnummer: (t) => [
    {
      required: true,
      message: t('validation', 'required.field.generic', 'Please, fill this field.'),
    },
  ],
  creditCardHolder: (t) => [
    {
      required: true,
      message: t('compra.formulario', 'error.tarjeta.titular', 'Por favor, introduce el nombre completo del titular, tal y como aparece en la tarjeta.'),
    },
    {
      type: 'string',
      pattern: /^([^0-9]*)$/,
      message: t('compra.formulario', 'error.tarjeta.titular'),
    },
  ],
  creditCardNumber: () => [
    {
      required: true,
    },
  ],
  creditCardType: (t) => [
    {
      required: true,
      message: t('compra.formulario', 'error.tarjeta.tipo'),
    },
  ],
  creditCardCvcDefault: (t) => [
    {
      required: true,
      message: t('compra.formulario', 'error.tarjeta.cvc'),
    },
  ],
  creditCardCvcAMEB: (t) => [
    {
      required: true,
      message: t('compra.formulario', 'error.tarjeta.cvc'),
    },
  ],
  expiryDate: (t) => [
    {
      required: true,
    },
    {
      validator: (_, value) => {
        let isValid = false;

        if (value === undefined) {
          return Promise.reject();
        }

        const [month, year] = value.split('/');

        if (month && year) {
          const parsedMonth = parseInt(month, 10);
          const parsedYear = parseInt(year, 10);

          const monthBetween = parsedMonth <= 12 && parsedMonth > 0;

          const baseYearValue = parsedYear > 1000 ? parsedYear : 2000 + parsedYear;

          const expiryDate = new Date(baseYearValue, parsedMonth - 1);
          const currentDate = new Date();

          const currentMonth = currentDate.getMonth();
          const currentYear = currentDate.getFullYear();

          const expiryMonth = expiryDate.getMonth();
          const expiryYear = expiryDate.getFullYear();

          if (monthBetween) {
            if (expiryYear > currentYear) {
              isValid = true;
            } else if (expiryYear === currentYear) {
              if (expiryMonth > currentMonth) {
                isValid = true;
              }
            }
          }
        }

        if (isValid) {
          return Promise.resolve();
        }

        return Promise.reject(t('compra.formulario', 'error.tarjeta.fecha'));
      },
    },
  ],
  requiredOption: (t) => [
    {
      required: true,
      message: t('validation', 'required.select.option', 'Please select an option.'),
    },
  ],
  requiredCountry: (t) => [
    {
      required: true,
      message: t('validation', 'required.select.country', 'PLease, select your country.'),
    },
  ],
  requiredLanguage: (t) => [
    {
      required: true,
      message: t('validation', 'required.selected.language', 'Please, select your language.'),
    },
  ],
  orderId: (t) => [
    {
      required: true,
      message: t('validation', 'required.order', '**Please input your order ID'),
    },
    {
      type: 'string',
      pattern: /^([\s]*)([459])([0-9]{9}([\s]*))$/,
      message: t('validation', 'invalid.order', '**Please input a valid order ID'),
    },
  ],
  orderIdContact: (t) => [
    {
      required: true,
      message: t('validation', 'required.order', '**Please input your order ID'),
    },
    {
      type: 'string',
      pattern: /^([\s]*)(([45])([0-9]{9})|(15)([0-9]{8}))([\s]*)$/,
      message: t('validation', 'invalid.order', '**Please input a valid order ID'),
    },
  ],
  orderIdNotRequired: (t) => [
    {
      type: 'string',
      pattern: /^([\s]*)(([45])([0-9]{9})|(15)([0-9]{8}))([\s]*)$/,
      message: t('validation', 'invalid.order', '**Please input a valid order ID'),
    },
  ],
  cedula: (t) => [
    {
      required: true,
      message: t('compra.formulario', 'info.campos.nif.co'),
      markets: ['CO'],
    },
    {
      type: 'string',
      pattern: /^[0-9]{1,}$/,
      message: t('compra.formulario', 'error.campos.nif.co'),
    },
  ],
  dniCL: (t) => [
    {
      required: true,
      message: t('compra.formulario', 'info.campos.nif.cl'),
      markets: ['CL'],
    },
    {
      type: 'string',
      pattern: /^[0-9]{7,8}[-][a-zA-Z0-9]$/,
      message: t('compra.formulario', 'error.campos.nif.cl'),
    },
  ],
  sku: (t) => [
    {
      required: true,
      message: t('validation', 'required.sku', '***SKU is required.'),
    },
    {
      type: 'string',
      pattern: /^[a-zA-Z0-9]{5,7}-[a-zA-Z0-9]{3,4}(-[a-zA-Z0-9]{3,4})?$/,
      message: t('validation', 'invalid.sku', '***Current SKU is not valid.'),
    },
  ],
  unrequiredSku: (t) => [
    {
      type: 'string',
      pattern: /^[a-zA-Z0-9]{5,7}-[a-zA-Z0-9]{3,4}(-[a-zA-Z0-9]{3,4})?$/,
      message: t('validation', 'invalid.sku', '***Current SKU is not valid.'),
    },
  ],
  file: (t) => [
    {
      required: true,
      message: t('validation', 'required.file', '***Please upload a file.'),
    },
  ],
  employee: (t) => [
    {
      required: true,
      message: t('validation', 'required.employee'),
    },
  ],
};
