import { array, bool, func, node, object, string } from 'prop-types';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';

import { useLocalStorage } from 'hooks/useLocalStorage';
import localeConfigs from 'utils/localeConfigs';
import { COOKIES, eraseCookie, getCookie, getLang, getMarket } from 'utils/helpers';
import { AUTO_BONDS, IS_CAMPER, IS_NNORMAL } from 'utils/constants';
import { isBlank } from 'utils/StringUtils';
import { retrieveBag } from 'actions/products';
import { dataLayerHandleEvent } from 'utils/dataLayers';
import { parseBagItemsEcommerceGA4 } from 'utils/gtmUtils';

const retrieveBagHandler = async ({
  locale,
  config,
  setBag,
  setBagLength,
  setPromoLabel,
  setPromoLabelType,
  setBagHasMemberOnlyProduct,
  setDisabledProducts,
}) => {
  try {
    const lang = getLang(locale);
    const market = getMarket(locale);
    const bagHash = getCookie(COOKIES.BAG);
    const autoVoucher = getCookie(COOKIES.AUTO_BONDS) || '';
    const voucher = getCookie(COOKIES.BONDS) || '';
    const voucherDiscount = IS_CAMPER && autoVoucher && autoVoucher === AUTO_BONDS.APPLY;
    const cookiesMarket = getCookie(COOKIES.BAG_MARKET);
    const showFreeShipping = config?.show_free_shipping || false;
    const showGiftPurchase = config?.show_gift_purchase || false;

    let updatedPromoLabel = null;
    let updatedPromoLabelType = 'progress';
    let currentBag = [];

    if (bagHash && cookiesMarket === market) {
      const payload = {
        hashOrderId: bagHash,
        profile: {
          countryId: market,
          languageId: lang,
        },
      };

      if (voucherDiscount || (IS_NNORMAL && !isBlank(voucher))) {
        payload.voucher = voucher;
      }

      const result = await retrieveBag({ payload });

      if (showFreeShipping) {
        updatedPromoLabel = currentBag?.freeShippingLabel || null;
      }
      if (showGiftPurchase) {
        updatedPromoLabel = currentBag?.gwplabel || currentBag?.gwplabelObtained || currentBag?.freeShippingLabel || null;
        if (currentBag?.gwplabelObtained && currentBag?.gwplabelObtained !== '') {
          updatedPromoLabelType = 'obtained';
        }
      }
      if (setDisabledProducts && !isBlank(result.blockedSkus)) {
        setDisabledProducts(result.blockedSkus);
      }

      if (result?.shoppingCart) {
        currentBag = result.shoppingCart;
      }
    }

    if (cookiesMarket !== market) {
      eraseCookie(COOKIES.BAG);
      eraseCookie(COOKIES.BAG_DATE);
      eraseCookie(COOKIES.BAG_MARKET);
      eraseCookie(COOKIES.SHIPMENT);
      eraseCookie(COOKIES.EMPLOYEE_ID);
      eraseCookie(COOKIES.PACKSTATION);
      eraseCookie(COOKIES.IS_CTR);
      eraseCookie(COOKIES.ZIP);
    }

    setBag(currentBag);
    setBagLength(currentBag.length);
    setPromoLabel(updatedPromoLabel);
    setPromoLabelType(updatedPromoLabelType);

    setBagHasMemberOnlyProduct(currentBag.filter((product) => product.membersOnly === true).length > 0);

    dataLayerHandleEvent({
      event: 'cart_sync',
      cart: {
        items: parseBagItemsEcommerceGA4(currentBag),
      },
    });

    return currentBag;
  } catch (e) {
    console.error('Init bag error', e);
    return null;
  }
};

export const i18nContext = createContext(undefined);

const I18nProvider = ({
  t,
  locale,
  countries,
  children,
  profileData,
  detectedCountry,
  appmode,
  additionalAttributes,
  userInteracted,
  i18nPage,
  storeData,
  setStoreData,
  unbxdInitialSearch,
}) => {
  const [bagLength, setBagLength] = useState(0);
  const [bag, setBag] = useState(null);
  const [bagHasMembersOnlyProduct, setBagHasMemberOnlyProduct] = useState(false);
  const [promoLabel, setPromoLabel] = useState(null);
  const [promoLabelType, setPromoLabelType] = useState('progress');
  const [searchQuery, setSearchQuery] = useState('');
  const [searchQueryShadow, setSearchQueryShadow] = useState('');
  const [disabledProducts, setDisabledProducts] = useState(null);
  // utilizo un queryShadow porque el searchQuery acciona un useEffect en la pagina de /search, asi que tengo que poder distinguirlo para el input en mobile que se puede accionar con el boton de la lupa.
  const [recommendedProductsForBag, setRecommendedProductsForBag] = useLocalStorage('bag-recommended', []);
  const [unbxdSearchResults, setUnbxdSearchResults] = useState(unbxdInitialSearch);

  const wrappedLocaleConfigs = {};

  Object.keys(localeConfigs).forEach((localeConfKey) => {
    wrappedLocaleConfigs[localeConfKey] = localeConfigs[localeConfKey](t);
  });

  useEffect(() => {
    // si la bolsa esta vacia quitar productos recomendados que se hayan quedado
    if (Array.isArray(bag) && bag.length === 0 && recommendedProductsForBag.length > 0) {
      setRecommendedProductsForBag([]);
    }
  }, [bag]);

  useEffect(() => {
    retrieveBagHandler({
      locale,
      config: profileData.config,
      setBag,
      setBagHasMemberOnlyProduct,
      setBagLength,
      setPromoLabel,
      setPromoLabelType,
      setDisabledProducts,
    });
  }, []);

  useEffect(() => {
    setUnbxdSearchResults(unbxdInitialSearch);
  }, [unbxdInitialSearch]);

  const giftCardList = useMemo(() => {
    return profileData?.giftCardList ?? [];
  }, [profileData?.giftCardList]);

  return (
    <i18nContext.Provider
      value={{
        t,
        locale,
        countries,
        localeConfigs: wrappedLocaleConfigs,
        profileData,
        bagLength,
        setBagLength,
        bag,
        setBag,
        bagHasMembersOnlyProduct,
        setBagHasMemberOnlyProduct,
        promoLabel,
        setPromoLabel,
        promoLabelType,
        setPromoLabelType,
        searchQuery,
        setSearchQuery,
        searchQueryShadow,
        setSearchQueryShadow,
        detectedCountry,
        appmode,
        additionalAttributes,
        recommendedProductsForBag,
        setRecommendedProductsForBag,
        userInteracted,
        i18nPage,
        storeData,
        setStoreData,
        disabledProducts,
        unbxdInitialSearch,
        unbxdSearchResults,
        setUnbxdSearchResults,
        giftCardList,
      }}
    >
      {children}
    </i18nContext.Provider>
  );
};

export function useI18n() {
  const context = useContext(i18nContext);
  if (context === undefined) {
    throw new Error('useI18n must be used within a I18nProvider');
  }
  return context;
}

I18nProvider.propTypes = {
  children: node,
  locale: string,
  countries: array,
  t: func,
  profileData: object,
  detectedCountry: string,
  appmode: bool,
  additionalAttributes: object,
  userInteracted: bool,
  i18nPage: string,
  storeData: object,
  setStoreData: func,
};

export default I18nProvider;
