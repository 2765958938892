import { useState, useMemo, useRef, useId } from 'react';
import { string, bool, object, oneOfType, array, number, any } from 'prop-types';

import { IMAGE_SIZE_MAPPER } from 'utils/constants/ux';
import { getSrcSet } from 'utils/helpers';

import styles from './style.module.css';
import type { Prettify } from 'types/utils';

const ERROR_IMG = '/assets-new/notfound.png';

type ResponsiveImageProps = Prettify<
  React.ComponentProps<'img'> & {
    pictureClassName?: string;
    sources?: { src?: string; media?: string }[];
  }
>;

export function ResponsiveImage({ src, alt = '', className, loading = 'lazy', pictureClassName, sources, ...rest }: ResponsiveImageProps) {
  const id = useId();
  const pictureRef = useRef(null);
  const stop = useRef(false);
  return (
    <picture ref={pictureRef} className={pictureClassName}>
      {sources?.map((s, idx) => <source key={`${id}-source-${idx}`} {...s} />)}
      <img
        src={src}
        alt={alt}
        className={className}
        loading={loading}
        onError={({ currentTarget }) => {
          if (stop.current) return;
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = ERROR_IMG;
          currentTarget.style.height = '100%';
          currentTarget.style.objectFit = 'scale-down';
          currentTarget.style.background = 'whitesmoke';
          stop.current = true;
        }}
        {...rest}
      />
    </picture>
  );
}

type ImgProps = React.ComponentProps<'img'>;

export function Img({ src, alt, ...rest }: ImgProps) {
  const [error, setError] = useState(false);
  if (error) {
    return <img className={styles.imageNotFound} alt="" src={ERROR_IMG} />;
  }
  return <img onError={() => setError(true)} className={styles.image} src={src} alt={alt} {...rest} />;
}

// FIXME: podemos hacer que las otherProps no esten en un objecto y que sean props normales
type ProgressiveImgProps = React.ComponentProps<'img'> & {
  iscamperone?: boolean;
};

export function ProgressiveImg({ src, alt, iscamperone, ...rest }: ProgressiveImgProps) {
  const [error, setError] = useState(false);

  if (error) {
    return <img {...rest} className={styles.imageNotFound} alt="" src={ERROR_IMG} loading="lazy" />;
  }

  if (iscamperone) {
    return <img {...rest} onError={() => setError(true)} src={src} alt={`Image of ${alt}`} />;
  }

  return (
    <picture>
      <source
        type="image/avif"
        srcSet={`
          ${src?.replace(/\.jpg|\.png/, '.avif')}
        `}
      />
      <source
        type="image/webp"
        srcSet={`
          ${src?.replace(/\.jpg|\.png/, '.webp')}
        `}
      />
      <img {...rest} onError={() => setError(true)} src={src} alt={`Image of ${alt}`} />
    </picture>
  );
}

type ProgressiveBlurredImgProps = {
  highQualitySrc: string | string[] | Record<string, string>;
  initLoaded?: boolean;
  alt: string;
  width?: React.ComponentProps<'img'>['width'];
  height?: React.ComponentProps<'img'>['height'];
  index: number;
  loading?: React.ComponentProps<'img'>['loading'];
  pictureClassName?: string;
};

function ProgressiveBlurredImg({
  highQualitySrc,
  initLoaded = false,
  alt,
  width,
  height,
  index,
  loading = 'lazy',
  pictureClassName,
}: ProgressiveBlurredImgProps) {
  const [isLoaded, setIsLoaded] = useState(initLoaded);
  const [error, setError] = useState(false);
  const isObjectSrcSet = typeof highQualitySrc === 'object' && highQualitySrc !== null;
  const isArraySrcSet = Array.isArray(highQualitySrc);
  const highQualitySrcIsSrcset = Array.isArray(highQualitySrc) || isObjectSrcSet;
  const srcSet = useMemo(
    () => (isObjectSrcSet && !isArraySrcSet ? highQualitySrc : getSrcSet(highQualitySrc)),
    [highQualitySrc, isObjectSrcSet, isArraySrcSet],
  );

  if (error) {
    return (
      <picture className={`${styles.image} image`}>
        <img className={`${styles.image} ${styles.imageNotFound}`} src={ERROR_IMG} alt="" />
      </picture>
    );
  }

  const imagesNotLazy = [0, 1];

  return (
    <>
      {highQualitySrcIsSrcset ?
        <picture className={`${styles.image} ${isLoaded ? '' : styles.imageNotLoaded} image ${pictureClassName ?? ''}`}>
          {/* TO DO: En el futuro, hacer lo mismo que hacemos en el source de webp para image/avif */}
          {Object.keys(srcSet)
            .filter((item) => item !== 'xs')
            .map((srcKey) => (
              <source
                key={`${alt}-${srcKey}`}
                type="image/avif"
                srcSet={`
                  ${srcSet[srcKey]?.replace(/\.jpg|\.png/, '.avif')}
                `}
                media={IMAGE_SIZE_MAPPER[srcKey]}
              />
            ))}
          {Object.keys(srcSet)
            .filter((item) => item !== 'xs')
            .map((srcKey) => (
              <source
                key={`${alt}-${srcKey}`}
                type="image/webp"
                srcSet={`
                  ${srcSet[srcKey]?.replace(/\.jpg|\.png/, '.webp')}
                `}
                media={IMAGE_SIZE_MAPPER[srcKey]}
              />
            ))}
          {Object.keys(srcSet)
            .filter((item) => item !== 'xs')
            .map((srcKey) => (
              <source
                key={`${alt}-${srcKey}`}
                type="image/jpg"
                srcSet={`
                  ${srcSet[srcKey]}
                `}
                media={IMAGE_SIZE_MAPPER[srcKey]}
              />
            ))}
          <img
            onLoad={() => setIsLoaded(true)}
            onError={() => setError(true)}
            src={srcSet.md}
            width={width}
            height={height}
            alt={alt}
            className={isLoaded ? styles.image : `${styles.image} ${styles.imageNotLoaded}`}
            loading={!loading || imagesNotLazy.includes(index) ? undefined : loading}
          />
        </picture>
      : <img
          onLoad={() => setIsLoaded(true)}
          onError={() => setError(true)}
          className={isLoaded ? styles.image : `${styles.image} ${styles.imageNotLoaded}`}
          src={typeof highQualitySrc === 'string' ? highQualitySrc : highQualitySrc.md}
          alt={alt}
          loading={!loading || imagesNotLazy.includes(index) ? undefined : loading}
        />
      }
    </>
  );
}

export default ProgressiveBlurredImg;
