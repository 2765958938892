import type { LocalStorageKeyOptions, SessionStorageKeyOptions } from './constants/storage';

export function checkWebStorage<T>(cb?: () => T): T | null {
  try {
    if (typeof window === 'undefined') {
      throw new Error('Web storage is only available in the browser');
    }
    if (!cb) {
      throw new Error('Callback is required');
    }
    return cb();
  } catch (error) {
    console.error('Error checking web storage', error);
    return null;
  }
}

export const storage = {
  local: {
    get: function localStorageGet<T>(key: LocalStorageKeyOptions) {
      return checkWebStorage(() => JSON.parse(localStorage.getItem(key) as string) as T);
    },
    set: function localStorageSet<T>(key: LocalStorageKeyOptions, value: T) {
      return checkWebStorage(() => localStorage.setItem(key, JSON.stringify(value)));
    },
    remove: function localStorageRemove(key: LocalStorageKeyOptions) {
      return checkWebStorage(() => localStorage.removeItem(key));
    },
  },
  session: {
    get: function sessionStorageGet<T>(key: SessionStorageKeyOptions) {
      return checkWebStorage(() => JSON.parse(sessionStorage.getItem(key) as string) as T);
    },
    set: function sessionStorageSet<T>(key: SessionStorageKeyOptions, value: T) {
      return checkWebStorage(() => sessionStorage.setItem(key, JSON.stringify(value)));
    },
    remove: function sessionStorageRemove(key: SessionStorageKeyOptions) {
      return checkWebStorage(() => sessionStorage.removeItem(key));
    },
  },
};
