import { COOKIES, getCookie, setCookie, getMarket, eraseCookie } from './helpers';
import { BAG_EXPIRATION_DAYS, AUTO_BONDS } from './constants/cookies';
import { isBlank } from './StringUtils';
import { defaultLocale } from './translations';
import { getCurrencySymbol } from './prices';

// 30 min: 30 * 60 * 1000 = 1_800_000
const ABANDONED_CART_INTERVAL = 1_800_000;

export function isBagAbandoned() {
  const cartUpdateDate = getCookie(COOKIES.BAG_DATE);
  if (!cartUpdateDate) return false;
  const updateDate = new Date(cartUpdateDate);
  if (!updateDate) return false;
  const difference = Date.now() - Date.parse(updateDate);
  return difference > ABANDONED_CART_INTERVAL;
}

export function updateBagDate() {
  setCookie(COOKIES.BAG_DATE, new Date().toISOString(), BAG_EXPIRATION_DAYS);
}

export function setBagCookies({ hash, market }) {
  setCookie(COOKIES.BAG, encodeURI(hash), BAG_EXPIRATION_DAYS);
  setCookie(COOKIES.BAG_MARKET, market, BAG_EXPIRATION_DAYS);
  updateBagDate();
}

export function prepareBagPayload({ locale, basePayload = {}, checkStock = true, allMandatory = true }) {
  const market = getMarket(locale);
  const payload = {
    profile: locale,
    checkStock,
    allMandatory,
    ...basePayload,
  };
  const cookiesBagId = getCookie(COOKIES.BAG);
  const autoVoucher = getCookie(COOKIES.AUTO_BONDS) || '';
  const voucher = getCookie(COOKIES.BONDS) || '';
  const cookiesMarket = getCookie(COOKIES.BAG_MARKET);
  const cookiesShipment = getCookie(COOKIES.SHIPMENT);
  const cookiesBonds = getCookie(COOKIES.BONDS);
  const cookiesZip = getCookie(COOKIES.ZIP);
  const cookiesEmployeeId = getCookie(COOKIES.EMPLOYEE_ID);
  const cookiesPackstation = getCookie(COOKIES.PACKSTATION);
  const cookiesCtr = getCookie(COOKIES.IS_CTR);
  const voucherDiscount = autoVoucher && autoVoucher === AUTO_BONDS.APPLY;

  if (voucherDiscount || voucher) {
    payload.voucher = voucher;
  }
  if (!isBlank(cookiesBagId)) {
    payload.hashOrderId = decodeURI(cookiesBagId);
  }
  if (cookiesMarket !== market) {
    payload.hashOrderId = undefined;
    if (cookiesBagId) {
      eraseCookie(COOKIES.BAG);
      eraseCookie(COOKIES.BAG_DATE);
    }
    if (cookiesZip) {
      eraseCookie(COOKIES.ZIP);
    }
    if (cookiesShipment) {
      eraseCookie(COOKIES.SHIPMENT);
    }
    if (cookiesMarket !== null && cookiesBonds) {
      eraseCookie(COOKIES.BONDS, COOKIES.DOMAIN);
      eraseCookie(COOKIES.AUTO_BONDS);
    }
    if (cookiesEmployeeId) {
      eraseCookie(COOKIES.EMPLOYEE_ID);
    }
    if (cookiesPackstation) {
      eraseCookie(COOKIES.PACKSTATION);
    }
    if (cookiesCtr) {
      eraseCookie(COOKIES.IS_CTR);
    }
  }

  return payload;
}

export function prepareOldBagPayload({ selectedSize, productSheet }) {
  let pricesPayload = productSheet.prices;

  const sizeLabel = !productSheet.isAccessory ? productSheet.sizes.filter((size) => size?.value === selectedSize)[0]?.label : selectedSize;

  const discountStrToNumber = pricesPayload?.discountStr ? Number(prices?.discountStr?.replace(/\D/g, '')) : 0;
  if (discountStrToNumber > pricesPayload.discount) {
    // tenemos que enviar el discount que mas beneficie
    pricesPayload.discount = discountStrToNumber;
  }

  if (pricesPayload.pricePerSize) {
    const sizeFound = productSheet.sizes.find((size) => size?.value === selectedSize);
    if (sizeFound) {
      pricesPayload = sizeFound?.price;
    }
  }

  const payload = {
    id: productSheet.code,
    price: pricesPayload,
    size: selectedSize,
    sizeLabel,
    gender: productSheet.realTarget || productSheet.target || '',
    thumbnail: productSheet.imageBag || '',
  };
  return payload;
}

export function sortAndOrderMiniBag({ bag, locale, currency: defaultCurrency }) {
  // si no tenemos hijos no hace falta hacer nada;
  if (!bag) return [];
  const parents = new Set([]);

  const childItems = bag.filter((item) => {
    const hasParentId = item.parentId !== undefined;
    if (hasParentId) parents.add(item.parentId);
    return hasParentId;
  });
  if (childItems.length === 0) return bag;

  const replacedLocale = locale ? locale.replace('_', '-') : defaultLocale;
  const localeToUse = /^([a-z]{2})-([A-Z]{2})$/.test(replacedLocale) ? replacedLocale : 'es-ES';
  const bagItemsWithoutChildren = bag.filter((item) => !item.parentId);
  const currency = defaultCurrency ?? bagItemsWithoutChildren?.[0]?.price?.currency ?? 'EUR';
  const currencySymbol = getCurrencySymbol({ locale: localeToUse, currency });
  return bagItemsWithoutChildren.map((item) => {
    if (item.price.hasSummed) return item;
    if (!parents.has(item.id)) return item;
    const itemsToAdd = childItems.filter((childItem) => childItem.parentId === item.id);
    if (!childItems.length) return item;

    // todo esto porque se liaron con kboix.
    const price = JSON.parse(JSON.stringify(item.price));
    itemsToAdd.forEach((childItem) => {
      price.current += childItem.price.current;
      price.currentEur += childItem.price.currentEur;
      price.discountEur += childItem.price.discountEur;
      price.discountLocal += childItem.price.discountLocal;
      price.discountPrice += childItem.price.discountPrice;
    });
    price.hasSummed = true;
    price.currentFormated = `${price.current} ${currencySymbol}`;
    price.discountPriceFormatted = `${price.discountPrice} ${currencySymbol}`;
    return {
      ...item,
      price: price,
      additionalProducts: itemsToAdd,
    };
  });
}

export function sortAndOrderBag({ bag, locale, currency: defaultCurrency }) {
  // si no tenemos hijos no hace falta hacer nada;
  if (!bag) return [];
  const bagCopy = JSON.parse(JSON.stringify(bag));
  const parents = new Set([]);

  const childItems = bag.filter((item) => {
    const hasParentId = item.parentId !== undefined;
    if (hasParentId) parents.add(item.parentId);
    return hasParentId;
  });
  if (childItems.length === 0) return bagCopy;

  const replacedLocale = locale ? locale.replace('_', '-') : defaultLocale;
  const localeToUse = /^([a-z]{2})-([A-Z]{2})$/.test(replacedLocale) ? replacedLocale : 'es-ES';
  const bagItemsWithoutChildren = bagCopy.filter((item) => !item.parentId);
  const currency = defaultCurrency ?? bagItemsWithoutChildren?.[0]?.price?.currency ?? 'EUR';
  const currencySymbol = getCurrencySymbol({ locale: localeToUse, currency });
  return bagItemsWithoutChildren.map((item) => {
    if (!parents.has(item.id)) return item;
    const itemsToAdd = childItems.filter((childItem) => childItem.parentId === item.id);
    const price = item.price;
    const prices = item.prices;

    itemsToAdd.forEach((childItem) => {
      price.current += childItem.price.current;
      price.discountEur += childItem.price.discountEur;
      price.discountLocal += childItem.price.discountLocal;
      price.discountPrice += childItem.price.discountPrice;
      price.previous += childItem.price.previous;
      prices.current += childItem.prices.current;
      prices.discountPrice += childItem.prices.discountPrice;
      prices.netPrice += childItem.prices.netPrice;
      prices.previous += childItem.prices.previous;
      prices.taxValue += childItem.prices.taxValue;
    });
    price.currentFormated = `${price.current} ${currencySymbol}`;
    price.discountPriceFormatted = `${price.discountPrice} ${currencySymbol}`;
    price.previousFormated = `${price.previous} ${currencySymbol}`;
    prices.currentFormated = `${prices.current} ${currencySymbol}`;
    prices.previousFormated = `${prices.previous} ${currencySymbol}`;

    return {
      ...item,
      additionalProducts: itemsToAdd,
    };
  });
}
