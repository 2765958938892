const COUNTRIES_NO_PERSONAL_SHOPPER = ['CL', 'JP'];

const ECI_CODE = 'T6';

const STORES_WITH_SUBTITLE = ['T875', 'T176', 'D956', 'D449'];

const STORE_TYPES = [
  {
    type: 'TIENDA CAMPER',
    label: ['tiendas.locator', 'camper.store', 'Camper Store'],
  },
  {
    type: 'RECAMPER',
    label: ['tiendas.locator', 'outlet.store', 'Outlet Store'],
  },
  {
    type: 'FRANCHISE',
    label: ['tiendas.locator', 'franchise.store', 'Franchise Store'],
  },
  {
    type: 'ESPACIO CAMPER',
    label: ['tiendas.locator', 'department.store', 'Department Store'],
  },
];

module.exports = {
  COUNTRIES_NO_PERSONAL_SHOPPER,
  ECI_CODE,
  STORES_WITH_SUBTITLE,
  STORE_TYPES,
};
