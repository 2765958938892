const IS_CAMPER = process.env.NEXT_PUBLIC_DOMAIN === 'camper';
const IS_NNORMAL = process.env.NEXT_PUBLIC_DOMAIN === 'nnormal';
const GLOBAL_E_SCRIPTS_ID = IS_CAMPER ? '799' : '30000428';
const DEFAULT_BASE_URL = process.env.INTEGRA_DOMAIN ?? 'https://www.camper.com';

module.exports = {
  IS_CAMPER,
  IS_NNORMAL,
  GLOBAL_E_SCRIPTS_ID,
  DEFAULT_BASE_URL,
};
