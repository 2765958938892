import * as v from 'valibot';

const emailSchema = v.pipe(
  v.string(),
  v.email(),
  v.custom<string, string>((input) => {
    if (typeof input !== 'string') return false;
    return ![
      '@gnail.com',
      '@gmail.con',
      '@hotnail.com',
      '@gmial.com',
      '@oultook.com',
      '@outbook.com',
      '@otlook.com',
      '@outlok.com',
      '@htmail.com',
      '@hitmail.com',
      '@hotmail.con',
      '@gmaiil.com',
      '@gmil.com',
      '@gmai.com',
      '@g.mail.com',
      '@hotmial.com',
      '@gmaiul.com',
      '@hotmil.com',
      '@gamail.com',
      '@oulook.com',
      '@gemail.com',
      '@gmail.comm',
      '@gmail.es',
      '@hotmail.cm',
      '@HTMAIL.COM',
      '@hormail.com',
      '@hotmaill.com',
      '@yhoo.com',
      '@gmIl.com',
      '@gmaail.com',
      '@homai.com',
      '@hotrmail.com',
      '@terra.es',
      '@hootmail.es',
      '@iclound.com',
      '@yahho.es',
      '@homail.es',
    ].some((emailEnd) => emailEnd.endsWith(input));
  }, 'invalid_email'),
);

export function parseEmail(email: string) {
  return v.safeParse(emailSchema, email);
}
