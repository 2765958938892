export function orderMobileImages(data) {
  if (!data || !Array.isArray(data)) return [];
  if (data.length === 0) return [];
  const imagesForProductSheet = [...data];

  if (imagesForProductSheet) {
    const [firstImage, secondImage, thirdImage, fourthImage] = data;

    if (imagesForProductSheet.length > 1) {
      imagesForProductSheet[0] = secondImage;
      imagesForProductSheet[1] = firstImage;
    }

    if (imagesForProductSheet.length > 3) {
      imagesForProductSheet[2] = fourthImage;
      imagesForProductSheet[3] = thirdImage;
    }
  }
  return imagesForProductSheet;
}

export function combineTwoShoesPrices({ shoeOnePrice, shoeTwoPrice }) {
  if (!shoeOnePrice && !shoeTwoPrice) {
    return null;
  }
  if (!shoeOnePrice && shoeTwoPrice) {
    return shoeTwoPrice;
  }
  if (shoeOnePrice && !shoeTwoPrice) {
    return shoeOnePrice;
  }

  return {
    current: shoeOnePrice.current + shoeTwoPrice.current,
    currentEur: shoeOnePrice.currentEur + shoeTwoPrice.currentEur,
    currency: shoeOnePrice.currency,
    currencySap: shoeOnePrice.currencySap,
    discountEur: shoeOnePrice.discountEur + shoeTwoPrice.discountEur,
    discountLocal: shoeOnePrice.discountLocal + shoeTwoPrice.discountLocal,
    pricePerSize: shoeOnePrice.pricePerSize,
    currentFormated: new Intl.NumberFormat('es-ES', {
      style: 'currency',
      currency: shoeOnePrice.currencySap,
    }).format(shoeOnePrice.current + shoeTwoPrice.current),
  };
}

const skuRegex = /[A-Z0-9]+(?:-[0-9]{1,3})?-[A-Z0-9]{3,5}$/;

export function extractSku(text?: string) {
  if (typeof text !== 'string') return null;
  const match = text.match(skuRegex);
  return match ? match[0] : null;
}
