import { useState, useEffect } from 'react';
import { Form, Input, Tooltip } from 'antd';
import { string, bool, array, node } from 'prop-types';

import FloatLabel from 'components/base/floatLabel';

import { IS_NNORMAL } from 'utils/constants/system';

import styles from './style.module.css';

const FormInput = ({
  name,
  floatLabel = '',
  required = false,
  rules = [],
  helper,
  children,
  dependencies = [],
  className,
  label,
  noStyle = false,
  width,
  tooltip,
  tooltipText,
  market = '',
}) => {
  const [focus, setFocus] = useState(false);
  const [help, setHelp] = useState(null);

  const handleOnFocus = () => {
    setFocus(true);
  };

  const handleOnBlur = () => {
    setFocus(false);
  };
  /*
    Respecto a que no funcione onBlur podríamos de alguna
    manera observar el onChange, llamar al validator por
    nuestra cuenta después de blur y cambiar la prop validateStatus
    en base al resultado
  */

  let inputContent = children;

  if (IS_NNORMAL) {
    if (name === 'city' && market !== 'US') {
      inputContent = <Input placeholder="" />;
    }
  }

  useEffect(() => {
    if (typeof window !== undefined) {
      const baseHelper = helper && focus ? <div dangerouslySetInnerHTML={{ __html: helper }} /> : null;
      let helperToUse = name === 'email' ? null : baseHelper;

      if (name === 'email') {
        const emailInput = document.querySelectorAll('input[type="email"]')[0];

        if (emailInput !== undefined) {
          if (!emailInput?.value) {
            helperToUse = baseHelper;
          }
        }
      }

      setHelp(helperToUse);
    }
  }, [name, helper, focus]);

  if (tooltip && tooltipText) {
    return (
      <Tooltip placement="bottom" title={<span dangerouslySetInnerHTML={{ __html: tooltipText }}></span>} overlayClassName={'tooltip'} arrowPointAtCenter>
        <Form.Item
          name={name}
          required={required}
          rules={rules}
          validateTrigger={['onChange', 'onBlur']} // no funciona onBlur
          hasFeedback
          className={styles.formItem}
          help={help}
          dependencies={dependencies}
          label={label}
          noStyle={noStyle}
        >
          {floatLabel === '' ?
            inputContent
          : <FloatLabel style={{ width }} className={className} label={floatLabel} name={name} onFocus={handleOnFocus} onBlur={handleOnBlur}>
              {inputContent}
            </FloatLabel>
          }
        </Form.Item>
      </Tooltip>
    );
  }
  return (
    <Form.Item
      name={name}
      required={required}
      rules={rules}
      validateTrigger={['onChange']} // no funciona onBlur
      hasFeedback
      className={styles.formItem}
      help={help}
      dependencies={dependencies}
      label={label}
      noStyle={noStyle}
    >
      {floatLabel === '' ?
        inputContent
      : <FloatLabel style={{ width }} className={className} label={floatLabel} name={name} onFocus={handleOnFocus} onBlur={handleOnBlur}>
          {inputContent}
        </FloatLabel>
      }
    </Form.Item>
  );
};

FormInput.propTypes = {
  name: string.isRequired,
  required: bool,
  floatLabel: string,
  rules: array,
  className: string,
  helper: string,
  children: node.isRequired,
  dependencies: array,
  label: string,
  noStyle: bool,
  width: string,
  tooltip: bool,
  tooltipText: string,
  market: string,
};

export default FormInput;
