import { logError } from 'utils/error';
import { createRequestHeaders, createRequestUrl, handleFetchResponse } from 'utils/request';
import { GET_POPUP_INFO } from 'utils/routes';

export type DefaultNewsletterData = {
  title: string;
  text: string;
  closeBgColor?: string;
};

export async function getNewsletterDefaultData({ locale }: { locale?: string }) {
  const url = createRequestUrl({ path: GET_POPUP_INFO, query: { profile: locale } });
  try {
    if (!locale || ['int', 'default'].includes(locale)) {
      throw new Error(`Invalid locale ${locale}`);
    }
    const r = await fetch(url, {
      method: 'GET',
      headers: createRequestHeaders(),
    });
    const response = await handleFetchResponse<DefaultNewsletterData>(r);
    if (response.ok === false) {
      throw response.error;
    }
    return response.json;
  } catch (error) {
    logError(error);
    return null;
  }
}
