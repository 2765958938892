const ASIAN_MARKETS = ['TW', 'TH', 'KR', 'JP', 'HK'];

const CLOUDFRONT_MISS = 'Miss from cloudfront';

const MARKETS_WITH_COUNTRIES_SELECTOR = ['CA', 'DE', 'EL', 'EN', 'ES', 'FR', 'IT', 'NL', 'PL', 'PT', 'US'];

const BRITISH_COUNTRIES = ['GB', 'XI'];

const FAKE_LANG_MAP = {
  mx: 'es',
  co: 'es',
  pe: 'es',
  cl: 'es',
  cn: 'zh',
};

module.exports = {
  ASIAN_MARKETS,
  CLOUDFRONT_MISS,
  MARKETS_WITH_COUNTRIES_SELECTOR,
  BRITISH_COUNTRIES,
  FAKE_LANG_MAP,
};
