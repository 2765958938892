export const LOCAL_STORAGE_KEYS = {
  USER_SUBSCRIBED: `user_subscribed`,
} as const;

export type LocalStorageKeyOptions = (typeof LOCAL_STORAGE_KEYS)[keyof typeof LOCAL_STORAGE_KEYS];

export const SESSION_STORAGE_KEYS = {
  LAST_PURCHASE_BOND: 'last_purchase_bond',
} as const;

export type SessionStorageKeyOptions = (typeof SESSION_STORAGE_KEYS)[keyof typeof SESSION_STORAGE_KEYS];
