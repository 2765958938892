import { useContext } from 'react';
import { Select, Input } from 'antd';
import { object, string, array, bool, func } from 'prop-types';

import FormInput from 'components/formInput';
import { ChevronSVG } from 'components/iconsSVG/chevron';

import { getMarket } from 'utils/helpers';

import { i18nContext } from 'context/i18n';

import styles from './style.module.css';

const { Option } = Select;

const PhoneInput = ({ info, className, rules, withFloatLabel = true, placeholder, onChange }) => {
  const { t, locale } = useContext(i18nContext);
  // se dejó de utilizar el set de error en state así que en optimization lo quitamos

  const market = getMarket(locale);

  return (
    <>
      <div className={`${styles.phoneWrapper} ${className || ''}`}>
        <FormInput name="preffix" rules={[{ required: false }]}>
          <Select defaultValue={market} suffixIcon={<ChevronSVG />}>
            {info && info.phonePrefixes && (
              <>
                {info.phonePrefixes.map((item, index) => (
                  <Option key={`preffix-${index}`} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </>
            )}
          </Select>
        </FormInput>
        <FormInput
          floatLabel={withFloatLabel ? t('generico', 'telefono', 'Telephone') : ''}
          name="telephone"
          rules={[rules === undefined ? { required: false } : rules]}
        >
          <Input onChange={onChange} placeholder={!withFloatLabel && placeholder ? placeholder : ''} type="tel" />
        </FormInput>
      </div>
    </>
  );
};

PhoneInput.propTypes = {
  info: object.isRequired,
  className: string,
  rules: array,
  withFloatLabel: bool,
  placeholder: string,
  onChange: func,
};

export default PhoneInput;
