import { createContext, useContext, type PropsWithChildren } from 'react';
import type { DefaultNewsletterData } from 'actions/header';

type ContextData = {
  newsletter: DefaultNewsletterData | null;
};

const AppContext = createContext<ContextData | undefined>(null);

export function AppDataProvider({ newsletter, children }: PropsWithChildren<ContextData>) {
  return <AppContext.Provider value={{ newsletter }}>{children}</AppContext.Provider>;
}

export function useAppData() {
  const context = useContext(AppContext);
  if (context === undefined) {
    throw new Error('useAppData must be used within a AppContextProvider');
  }
  return context;
}
