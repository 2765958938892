import { getDomainValue } from 'utils/domain';

export const DEFAULT_SEO = getDomainValue({
  camper: {
    title: 'Camper',
    description:
      'Buy new shoes, boots, sneakers and accessories from the collection. Contemporary designs, original spirit. Free shipping and 2 years warranty.',
  },
  nnormal: {
    title: 'NNORMAL',
    description:
      'Buy new shoes, boots, sneakers and accessories from the collection. Contemporary designs, original spirit. Free shipping and 2 years warranty.',
  },
  camperlab: {
    title: 'Camperlab',
    description: '',
  },
});
