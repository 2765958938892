module.exports = {
  CREATE_ACCOUNT: '/account/create',
  LOGIN_ROUTE: '/account/login',
  USER_INFO_ROUTE: '/account/info',
  I18N_MULTIPLE_ROUTE: '/labels/multiple',
  I18N_CREATE_ROUTE: '/labels/',
  I18N_GET_SINGLE: '/labels/single',
  PRODUCTS_LIST_ROUTE: '/products/list',
  PRODUCT_ROUTE: '/products/sheet/',
  PRODUCT_ROUTE_LIVE: '/products/sheet/live',
  GET_ROKU_STOCK: '/products/get-roku-stock',
  CART: '/cart',
  CHECKOUT_INFO: '/checkout',
  SEND_INVOICE: '/orders/sendInvoice',
  REVIEW_DELIVERY: '/orders/reviewDelivery',
  PRODUCT_CREATE_ORDER: '/orders/create',
  PRODUCT_CANCEL_ORDER: '/orders/cancel',
  PRODUCT_GET_ORDER_INFO: '/orders/getInfo',
  ORDER_REJECT_1: (reference) => `/orders/${reference}/reject/step1`,
  ORDER_REJECT_2: (reference) => `/orders/${reference}/reject/step2`,
  ORDER_MODIFY_SIZE_1: (reference) => `/orders/${reference}/modifySize/step1`,
  ORDER_MODIFY_SIZE_2: (reference) => `/orders/${reference}/modifySize/step2`,
  ORDER_CHECK_USER: '/orders/checkTokenCaptcha',
  PRODUCTS_SEARCH: '/search/search',
  PRODUCTS_SUGGESTIONS: '/search/suggestions',
  SEARCH_GRID_SEEL_ALL: '/search/grid-see-all',
  SEARCH_URLS: '/search/urls',
  PRODUCT_COLLECTIONS: '/products/collection',
  PAYMENT_CONFIRM: '/orders/payments/confirm',
  PRODUCTS_CAMPAIGN: '/landingCampaign/',
  PRODUCT_SIZE_GUIDE: '/products/size-guide',
  LEGAL: '/legal',
  ADD_TO_BAG: '/cart/add',
  RETRIEVE_BAG: '/cart/retrieveshoppingcart',
  CART_CHANGE_SIZE: '/cart/change-size',
  DELETE_FROM_BAG: '/cart/delete',
  ADYEN_DETAILS: '/orders/payments/adyen/details',
  CHECK_PROFILE_ROUTE: '/global/check-profile',
  CHECKOUT_EXPRESS_FORM_ROUTE: '/checkout-express/form',
  CHECKOUT_EXPRESS_RETURN_DATA_ROUTE: '/checkout-express/return-data',
  CHECKOUT_EXPRESS_GET_DATA_ROUTE: '/checkout-express/data',
  FIND_AVAIL_STORES: '/products/instore-avail/stores',
  NOTIFY_ME: '/products/notifyme',
  COUNTRIES_ROUTE: '/global/countries',
  PAYMENT_METHODS_GET_ROUTE: '/global/payment-methods',
  SHIPPING_METHODS_GET_ROUTE: '/global/shipping-methods',
  CHECK_GIFTCARD: '/orders/checkgiftcard',
  TWS_EVENT_SUBSCRIBE: '/tws-events/suscribe',
  TWS_EVENT_UNSUBSCRIBE: '/tws-events/unsuscribe',
  TWS_EVENT_CONDITIONS: '/tws-events/getEventTcUrl',
  NEWSLETTER_SUBSCRIBE: '/newsletter/insert',
  NEWSLETTER_UNSUBSCRIBE: '/newsletter/unsubscribe',
  NEWSLETTER_UPDATE: '/newsletter/update',
  NEWSLETTER_CHECK_STATUS: '/newsletter/check-suscribed',
  PRODUCTS_RECOMMENDED: '/products/recommended',
  STORE_ROUTE: '/store-locator/store',
  STORES_ROUTE: '/store-locator/stores',
  STORES_BY_COUNTRY: '/store-locator/storesByCountry',
  STORES_BY_CITY: '/store-locator/storesByCity',
  RIBBON: '/header/header-ribbon',
  FAQS: '/FAQs/all',
  FAQS_SIZE_GUIDE_ROUTE: '/FAQs/size-guide',
  NEWSLETTER_INFO_ROUTE: '/newsletter/popup',
  SEO: '/seo/',
  BOOK_APPOINTMENT: '/products/instore-avail/book-appointment',
  MODIFY_ACCOUNT: '/account/modify',
  CHANGE_PASSWORD: '/account/updatePassword',
  RECOVER_PASSWORD: '/account/recoverPassword',
  SET_NEW_PASSWORD: '/account/setNewPassword',
  REGISTER_PELOTAS: '/account/registerPelotas',
  ADD_PRODUCT_TO_WISHLIST: '/account/wishlistAddProduct',
  REMOVE_PRODUCT_FROM_WISHLIST: '/account/wishlistRemoveProduct',
  GET_WISHLIST: '/account/wishlist',
  DELETE_MY_ACCOUNT: '/account/delete',
  EMPLOYEE_LOGIN_ROUTE: '/userweblogin',
  CHECK_WHOLESALER: '/userweblogin/check',
  PAYMENT_REDIRECT_FORM_ROUTE: '/orders/payments/redirect/form',
  DELIVERY_POINTS: '/cart/deliveryPoints',
  ORDER_SEND_MAIL: '/orders/sendMail',
  NORMALIZE_VALUES: '/checkout-express/normalize-values',
  REGIONS_AND_CITIES: '/global/regions-and-cities',
  APPLEPAY_CREATE_SESSION: '/orders/payments/applepay/create-session',
  APPLEPAY_AUTHORIZE: '/orders/payments/applepay/authorize',
  LOG_ROUTE: '/log',
  USERS_CUSTOMER_SERVICE: '/customerService/users',
  NEWSLETTER_PREFERENCES: '/newsletter/preferenceCenter',
  GET_NEWSLETTER_PREFERENCES: '/newsletter/getPreferenceCenter',
  CHECK_PRODUCT_IN_WISHLIST: '/account/checkWishlist',
  SELLIGENT_POPUP: '/global/selligentPopup',
  EXPRESS_SHOPPING_GET_DATA_ROUTE: '/express-shopping/data',
  EXPRESS_SHOPPING_SEND_EMAIL_LINK: '/express-shopping/send-email-link',
  CREATE_OFFLINE_ORDER: '/orders/createOfflineOrder',
  CREATE_CONTINGENCY_ORDER: '/orders/createContingencyOrder',
  COUNTRIES_IPAD: '/global/countriesIpad/',
  GET_POPUP_INFO: '/header/popup-info',
  DONATION_BEGIN: '/donation/begin',
  DONATION_DONATE: '/donation/donate',
  GET_RETURN_LABEL: '/orders/getReturnLabel',
  CMS_SLUG: '/cms/api/content/tiers-banners-homes/slugs',
  TRANSLATE_SLUG: '/api/translate-slug',
  POST_TAKEBACK: '/afterSales/takeback',
  GET_TAKEBACK_CREDIT: '/afterSales/takeback/credit',
  GET_OTHER_COLORS: '/products/other-colors',
  GET_DROPPOINT_URL: '/afterSales/takeback/droppointInfo',
  I18N_LABELS: '/label-analysis/',
  CROSS_SELLING: '/products/cross-selling',
  GLOBAL_RETURN_METHODS: '/global/return-methods',
  I18N_NEW: '/labels/multiple-new',
  KBOIX_SUGGESTIONS: '/suggestions',
  KBOIX_TIPS: '/tips',
  KBOIX_TIPS_FEEDBACK: (id) => `/tips/${id}/feedback`,
  KBOIX_TIPS_REGISTER: '/tips/register',
  KBOIX_RACES: '/race',
  KBOIX_USER: '/user',
  NNORMAL_WORLD_UNSUBSCRIBE: '/user/newsletter',
  NNORMAL_WORLD_OFFLINE_REGISTER: '/orders/kboixShopForm',
  CHILE_COMUNAS: '/global/comunas',
  GRID_PROPS: '/grid/get-grid',
  STORE_LOGIN: '/storelogin',
  STORE_EMPLOYEES: '/cart/getstoreemployees',
  CART_BANNERS: '/cart/getcollectiontags',
  CONTACT_INFO: '/contacts',
  ENRICHMENT_TWS: '/tws-events/clienteling',
  PRODUCT_RESERVATION: '/products/reservation',
  USER_EQUIPMENT: '/my-equipment/list',
  USER_EQUIPMENT_AUTHORIZE: '/my-equipment/authorize',
  IPAD_MENU: '/ipad/home',
  DEBUG: '/debug',
  COMPLETE_LOOK: '/complete-the-look',
  SAVE_IMAGE: '/complete-the-look/save',
  RETRIEVE_IMAGE: '/complete-the-look/retrieve',
};
